import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { isEmpty } from "../../utils/helper.util";
import { HEADER_LINKS } from "../../constants/routes";
import { headerLinkShoworNot } from "../../redux/actions";
import { axios } from "../../services";

export default function LoadingLayout() {
	const _headersLink = useSelector((state) => state?.multiProduct?.headersLink);
	const dispatch = useDispatch();

	const getHeaderLink = () => {
		axios
			.authGet(HEADER_LINKS)
			.then(({ data: data }) => {
				const name = data?.map((item) => item?.name);
				dispatch(headerLinkShoworNot(name, "HEADER_LINKS"));
				localStorage?.setItem("headerLink", name);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		getHeaderLink();
	}, []);

	return <Suspense fallback={""}>{!isEmpty(_headersLink) ? <Outlet /> : ""}</Suspense>;
}
