import { axios } from ".";
import { ROUTES } from "../constants";

export const getWishlistTotal = async () => {
	return await axios.authGet(ROUTES.GET_ALL_WISHLIST_PRODUCT_TOTAL).then(({ data: { totalWishList } }) => {
		return totalWishList;
	});
};

export const getCartTotal = async () => {
	return await axios.authGet(ROUTES.GET_CART_TOTAL).then(({ data: { totalCart } }) => {
		return totalCart;
	});
};
