import React from "react";
import Header from "./header.component";
import Footer from "./footer.component";
import { BreadCrumb } from "../breadCrumb/BreadCrumb.component";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function AuthLayout({ children, NotshowBreadCrumb }) {
	window.scrollTo(0, 0);
	return (
		<>
			<div className="layout">
				<ToastContainer />
				<Header />
				{!NotshowBreadCrumb ? "" : <BreadCrumb />}
				{children}
				<Footer />
			</div>
		</>
	);
}
