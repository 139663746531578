import { isArray } from "lodash";
import { toast } from "react-toastify";
import { API_BASE_URL } from "../constants/config";
import AuthStorage from "./authStorage";

export const isEmpty = (value) => {
	if (value === null || value === "null") {
		return true;
	}
	if (typeof value === "object") {
		return Object.keys(value).length === 0;
	}
	return (isArray(value) && value.length === 0) || value === undefined || value === "undefined" || value === null || value === "";
};

export const toaster = {
	error: (message, config = {}) =>
		toast.error(message, {
			position: "top-right",
			autoClose: 1500,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			...config,
		}),
	success: (message, config = {}) =>
		toast.success(message, {
			position: "top-right",
			autoClose: 1500,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "light",
			...config,
		}),
	warning: (message, config = {}) =>
		toast.warning(message, {
			position: "top-right",
			autoClose: 1500,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "light",
			...config,
		}),
	info: (message, config = {}) =>
		toast.info(message, {
			position: "top-right",
			autoClose: 1500,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "light",
			...config,
		}),
};

export const removeSymbols = (inputValue) => {
	return inputValue.replace(/[^a-zA-Z]/g, "");
};

export const handleInputTextNumber = (inputValue) => {
	const modifiedInput = removeSymbols(inputValue);
	return modifiedInput;
};

export const removeSymbolsNumber = (inputValue) => {
	return inputValue.replace(/^(1\d|2\d|\d{1})$/g, "");
};

export const handleInputText = (inputValue) => {
	const modifiedInput = removeSymbolsNumber(inputValue);
	return modifiedInput;
};

export const removeSymbolsText = (inputValue) => {
	return inputValue.replace(/[^0-9\s]/g, "");
};

export const handleInputNumber = (inputValue) => {
	const modifiedInput = removeSymbolsText(inputValue);
	return modifiedInput;
};

export const GetFormatedAmount = (amount, withCrDr = false, abs = true) => {
	let formatedAmount = withCrDr ? "" : "";
	if (!Number.isNaN(amount)) {
		amount = Number(amount).toFixed(2);

		if (abs) {
			formatedAmount = new Intl.NumberFormat("en-IN", {
				style: "decimal",
				currency: "INR",
				minimumFractionDigits: 2,
			}).format(Math.abs(amount));
		} else {
			formatedAmount = new Intl.NumberFormat("en-IN", {
				style: "decimal",
				currency: "INR",
				minimumFractionDigits: 2,
			}).format(amount);
		}
	}

	if (withCrDr) {
		amount < 0 ? (formatedAmount += " Cr.") : (formatedAmount += " Dr.");
	}

	return formatedAmount;
};

export const prepateSelectDropdown = (array, field1 = null, field2) => {
	if (isEmpty(array)) {
		return [];
	}
	return array
		.map((item) => {
			return {
				label: isEmpty(field1) ? item : item[field1],
				value: isEmpty(field2) ? item : item[field2],
			};
		})
		.filter((e) => !isEmpty(e.label));
};

export const checkFileType = (url) => {
	const fileExtension = url?.split(".").pop().toLowerCase();

	const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "svg"];

	const videoExtensions = ["mp4", "avi", "mov", "wmv", "mkv", "flv", "webm", "mpeg"];

	if (imageExtensions.includes(fileExtension)) {
		return "photo";
	}

	if (videoExtensions.includes(fileExtension)) {
		return "video";
	}

	return "unknown";
};

export const downloadFile = (url, data = {}) => {
	return new Promise((resolve, reject) => {
		if (!data?.file_name) {
			reject("file name is required");
			return;
		}
		var xhr = new XMLHttpRequest();
		xhr.open("GET", `${API_BASE_URL}${url}`, true);
		xhr.responseType = "arraybuffer";
		xhr.setRequestHeader("Authorization", "Bearer " + AuthStorage.getToken());
		xhr.onload = function (e) {
			if (this.status === 200) {
				var blob = new Blob([this.response], { type: "application/pdf" });
				var link = document.createElement("a");
				link.href = window.URL.createObjectURL(blob);
				link.download = data?.file_name;
				link.click();
				resolve();
			} else {
				reject();
			}
		};
		xhr.send();
	});
};

export const getImageUrl = (url) => {
	if (isEmpty(url)) {
		return url;
	}
	const isAbsoluteUrl = /^(?:\w+:)\/\//.test(url);
	if (isAbsoluteUrl) {
		return url;
	} else {
		return `${API_BASE_URL}${url}`.replace(/([^:]\/)\/+/g, "$1");
	}
};

export function _bool(val) {
	return !isEmpty(val) && (val === true || val === "true") ? true : false;
}

export function handleGoToTop() {
	window.scrollTo({ top: 0, behavior: "smooth" });
}
