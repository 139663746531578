import React from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

export const ModelComponent = ({
  show = false,
  onHide,
  headerContent = "",
  children,
}) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="p-2 border-0 justify-content-end">
        <Modal.Title id="contained-modal-title-vcenter">
          {headerContent}
          <Button
            type="button"
            className="text-dark bg-transparent login--close"
            onClick={onHide}
          >
            <i className="bi bi-x-lg"></i>
          </Button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};
