//  ======  {{ PAGE'S ROUTES }} ======= //

export const HOME = "/";
export const ADMIN = "/admin";
export const GEMSTONE_PAGE = "/gemstone";
export const GEMSTONE_FILTER_PAGE = "/gemstone/:shape";
export const GEMSTONE_DETAIL_PAGE = "/stone-detail/:id";
export const CART = "/cart";
export const WHISLIST = "/wishlist";
export const CONTACT_US = "/contact-us";
export const MY_ACCOUNT = "/my-account";
export const MY_ACCOUNT_WITH_ORDER_PAYMENT_ID = "/my-account/:id";
export const WEDDING_RINGS = "/wedding-rings";
export const JEWELRY_PAGE = "/jewellery";
export const JEWELRY_DETAIL_PAGE = "/jewellery-detail/:id";
export const DIAMOND_FILTER_PAGE = "/diamond/:shape";
export const DIAMOND_DETAILS = "/diamond-detail/:id";
export const LAB_COLOR_DIAMOND = "/diamond/lab-created-colored-diamonds";
export const NATURAL_COLOR_DIAMOND = "/diamond/natural-colored-diamonds";
export const COLOR_DIAMOND = "/diamond/colored";
export const PRODUCT_FILTER_PAGE = "/engagement-rings/:type";
export const ABOUT_US = "/about-us";
export const CUT_PAGE = "/cut";
export const COLOR_PAGE = "/color";
export const CLARITY_PAGE = "/clarity";
export const CARAT_PAGE = "/carat";
export const CERTIFICATE_PAGE = "/certificate";
export const COST_PAGE = "/cost";
export const EDUCATION_DETAILS = "/round";
export const PRINCESS_DETAILS = "/princess";
export const CUSHION_DETAILS = "/cushion";
export const OVAl_DETAILS = "/oval";
export const EMERALD_DETAILS = "/emerald";
export const PEAR_DETAILS = "/pear";
export const ASSCHER_DETAILS = "/asscher";
export const RADIANT_DETAILS = "/radiant";
export const MARQUIES_DETAILS = "/marquies";
export const HEART_DETAILS = "/heart";
export const PRIVACY_POLICY = "/privacy-policy";
export const RETURN_POLICY = "/return-policy";
export const SHIPPING_POLICY = "/shipping-policy";
export const TERMS_AND_CONDITIONS = "/terms-and-conditions";
export const ENGAGEMENT_RINGS = "/engagement-rings";
export const GEMSTONES = "/gemstone";
export const DIAMOND_PAGE = "/diamond";
export const PAGE_404 = "/page-404";
export const WEDDING_RING_FILTER = "/rings/:type";
export const JWELLERY_FILTER = "/jewellery/:types/:type";
export const COLOR_DIAMOND_DETAIL = "/colored-diamond-detail/:id";
export const CUSTOMER_REGISTER = "/register";
export const BLOG_PAGE = "/blog";
export const COMPLETE_RING = "/complete-ring";
export const COMPLETE_EARRING = "/complete-earring";
export const COMPLETE_NECKLACE = "/complete-necklace";
export const BLOG_DETAILS = "/blog-details/:id";
export const FAQ_PAGE = "/faq's";
export const GEMSTONE_ENGAGEMENT_RING_SETTING = "/gemstone-engagement-ring-settings";
export const GIFTS = "/gifts";
export const FORGOT_PASSWORD = "/forgot-password";
export const RESET_PASSWORD = "/reset-password/:email";

//  ====== {{ API ROUTES }} ====== //

export const LOGIN = "/login";
export const REGISTER = "/register";
export const GET_GEMSTONE = "/gemstone";
export const GET_GEMSTONE_BY_ID = "/gemstone/";
export const GET_SHAPE = "/shape";
export const GET_LAB = "/lab";
export const GET_DIAMOND = "/diamonds";
export const GET_DIAMOND_BY_ID = "/diamonds/";
export const GET_PRODUCT = "/product";
export const GET_PRODUCT_BY_ID = "/product/";
export const GET_ATTRIBUTES = "/options/attribute/";
export const GET_GESMTONE_PRICE = "/gemstone/priceRange";
export const GET_DIAMOND_PRICE = "/diamonds/priceRange";
export const GET_DIAMOND_CARAT = "/diamonds/caratRange";
export const GET_DIAMOND_TABLE_RANGE = "/diamonds/tableRange";
export const GET_DIAMOND_DEPTH = "/diamonds/depthRange";
export const ADD_GEMSTONE_TO_WISHLIST = "/wishListGemstone/";
export const GET_ALL_GEMSTONE_WISHLIST_DATA = "/wishListGemstone";
export const ADD_DIAMOND_TO_WISHLIST = "/wishListDiamond/";
export const GET_ALL_DIAMOND_WISHLIST_DATA = "/wishListDiamond";
export const GET_ALL_WISHLIST_PRODUCT_TOTAL = "/customer/totalWishList";
export const GET_ALL_WISHLIST_PRODUCT_DATA = "/wishListProduct";
export const ADD_PRODUCT_TO_WISHLIST = "/wishListProduct/";
export const GET_PRODUCT_PRICE = "/product/priceRange";
export const GET_CUSTOMER_PROFILE = "/customer";
export const CHANGE_PASSWORD = "/customer/password";
export const GET_SUBCATEGORY = "/subcategory/category-wise/";
export const GET_ALL_SUBCATEGORY = "/list/subcategory";
export const GET_INTENSITY = "/coloredDiamond/intensityRange";
export const GET_COLORED_DIAMOND_PRICE = "/coloredDiamond/priceRange";
export const GET_COLORED_DIAMOND = "/coloredDiamond";
export const GET_COLORED_DIAMOND_COLOR = "/coloredDiamond/colorRange";
export const GET_COLORED_DIAMOND_CARAT = "/coloredDiamond/caratRange";
export const ADD_PRODUCT_TO_CART = "/cart";
export const DELETE_PRODUCT_TO_CART = "/cart/";
export const UPDATE_QUANTITY = "/cart/";
export const MOVE_TO_WISHLIST = "/cart/move/";
export const GET_CATEGORY = "/list/category";
export const GET_CART_TOTAL = "/cart/count";
export const GET_BLOG_CATEGORY = "/blog";
export const GET_ALL_CATEGORY = "/list/blog_categories";
export const GET_BLOG_CATEGORY_BY_ID = "/blog/";
export const GET_FAQ = "/faq";
export const GET_ORDERS = "/orders";
export const GET_ORDERS_BY_ID = "/orders/";
export const GET_ALL_PRODUCT_QTY = "/orders/order-products/";
export const CREATE_ORDER_PAYMENT = "/orders/payment/create";
export const CREATE_RETURN_ORDERS = "/return-orders";
export const CANCEL_ORDER = "/orders/cancel-order-request";
export const FEATURED_PRODUCT = "/featureProduct";
export const OUR_PRODUCT = "/ourProduct";
export const HEADER_LINKS = "/page";
export const HOME_PAGE_SLIDER = "/link_up";
export const ORDER_CREATE = "/orders/create";
export const GET_APP_SETTTING = "/app-settings";
export const REMOVE_ENGRAVING = "/cart/engraving/";
export const GET_NOTIFICATiONS = "/notifications";
export const REMOVE_NOTIFICATION = "/notifications/";
export const READ_NOTIFICATION = "/notifications/read";
export const CLEAR_ALL_NOTIFICATION = "/notifications/clear-all";
export const UNREAD_NOTIFICATION = "/notifications/unread-notifications";
export const GET_POPULER_ENGAGEMENT_RING = "/popularEngagement";
export const GET_POPULER_WEDDING_RING = "/popularWedding";
export const GET_POPULER_GIFT = "/popularGift";
export const CONTACT_US_API = "/contact-us";
export const DOWNLOAD_INVOICE = "orders/download-invoice";
export const PRODUCT_BY_SKU = "product/by-skus";
export const GET_CARAT_BY_IMAGES = "/product/carats";
export const GET_PRODUCT_IMAGES = "/product/product-images";
export const FORGOT_PASSWORD_ROUTE = "/forgot-password/";
export const RESET_PASSWORD_ROUTE = "/reset-password/";
export const GET_PRODUCT_USING_SEARCH = "/list/product";
export const GET_COUNTRY = "/list/countries";
export const GET_STATE = "/list/states";
export const GET_CITIES = "/list/cities";

export const filterRoute = (route) => {
	return route.substring(1);
};
