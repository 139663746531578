import React from "react";
import { PuffLoader } from "react-spinners";

const Loader = ({ data }) => {
	return (
		<div className={`${data ? data : "loader-container"} position-relative`}>
			<div className="d-flex justify-content-center align-items-center">
				<PuffLoader color="#C29985" />
			</div>
		</div>
	);
};

export default Loader;
