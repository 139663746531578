export const shortingFilter = [
	{ value: "newest", label: "Newest" },
	{ value: "lPrice", label: "Price (Low to High)" },
	{ value: "hPrice", label: "Price (High to Low)" },
];

export const footerInformationArray = [
	{
		name: "privacy policy",
		path: "/privacy-policy",
	},
	{
		name: "return policy",
		path: "/return-policy",
	},
	{
		name: "shipping policy",
		path: "/shipping-policy",
	},
	{
		name: "Terms & Conditions",
		path: "/terms-and-conditions",
	},
	{
		name: "contact us",
		path: "/contact-us",
	},
	{
		name: "FAQ's",
		path: "/faq's",
	},
	{
		name: "Blog",
		path: "/blog",
	},
];

export const servicePolicyArray = [
	{
		title: "Free Shipping",
		sub_title: "Free shipping all order",
		icon: "bi-airplane",
	},
	{
		title: "Support 24/7",
		sub_title: "Support 24 hours a day",
		icon: "bi-question-circle",
	},
	{
		title: "Money Return",
		sub_title: "days for free return",
		icon: "bi-reply-fill",
	},
	{
		title: "100% Payment Secure",
		sub_title: "We ensure secure payment",
		icon: "bi-credit-card-2-back",
	},
];
