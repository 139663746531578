import React, { useEffect, useState } from "react";
import { Accordion, Col, Container, Offcanvas, Row } from "react-bootstrap";
import "./LayOut.css";
import useWidth from "../hooks/useWidth";
import { ModelComponent } from "./model.component";
import { CONFIG, ROUTES } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import Login from "../login/Login.component";
import { getCartTotal, getWishlistTotal } from "../../services/api";
import {
	addMultiProduct,
	addMultiProductGemstone,
	addToCartCount,
	deleteStartWithGemstone,
	logout,
	multiProductFlag,
	startWithGemstone,
	wishListCount,
} from "../../redux/actions";
import AuthStorage from "../../utils/authStorage";
import {
	allCategory,
	appSettings,
	deleteMultiProductDiamond,
	deleteMultiProductGemstone,
	deleteMultiProductRing,
	diamondSettingShowOrNot,
	makeJewellery,
	multiProductSettingShowOrNot,
} from "../../redux/actions/auth.action";
import {
	CLEAR_ALL_NOTIFICATION,
	GET_APP_SETTTING,
	GET_NOTIFICATiONS,
	READ_NOTIFICATION,
	REMOVE_NOTIFICATION,
	UNREAD_NOTIFICATION,
} from "../../constants/routes";
import { axios } from "../../services";
import { Link } from "react-router-dom";
import { API_BASE_URL } from "../../constants/config";
import io from "socket.io-client";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "./loader.component";
import { isEmpty } from "../../utils/helper.util";
import TimeFormatted from "../ui/TimeFormater.component";
import DynamicSearch from "../ui/DynamicSearch.component";

const Header = () => {
	const { width } = useWidth();
	const [isOpen, setIsOpen] = useState(false);
	const [page, setPage] = useState(0);
	const wishlistTotal = useSelector((state) => state?.wishlist?.count);
	const addToCartTotals = useSelector((state) => state?.wishlist?.cartCount);
	const logoImage = useSelector((state) => state?.multiProduct?.appSettings);
	const userName = useSelector((state) => state?.auth?.user);
	const userToken = useSelector((state) => state?.auth?.token);
	const [unreadNotifications, setUnreadNotifications] = useState(0);
	const [allNotifications, setAllNotifications] = useState({
		totalNotification: 0,
		data: [],
		page: 0,
		rowPerPage: 10,
		flag: false,
	});
	const [isModelOpen, setIsModelOpen] = useState(false);
	const [show, setShow] = useState(false);
	const userLogin = AuthStorage.isUserAuthenticated();
	const headerLink = useSelector((state) => state?.multiProduct?.headersLink);
	const showMobileHeader = 1199;
	const notShowMobileHeader = 1200;
	const dispatch = useDispatch();

	const getCategory = () => {
		axios
			.authGet(ROUTES.GET_CATEGORY)
			.then(({ data: data }) => {
				const _data = data?.map((item) => item?.name);
				dispatch(allCategory(_data, "ALL_CATEGORY"));
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getNotification = (data) => {
		axios
			.authGet(GET_NOTIFICATiONS, {
				page: data?.page,
				rowsPerPage: allNotifications?.rowPerPage,
			})
			.then(({ data: { count, rows } }) => {
				if (data?.page === 0) {
					setAllNotifications((prev) => ({
						...prev,
						totalNotification: count,
						data: rows,
					}));
				} else {
					setAllNotifications((prev) => ({
						...prev,
						totalNotification: count,
						data: [...allNotifications?.data, rows].flat(),
					}));
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getUnreadNotifications = () => {
		axios
			.authGet(UNREAD_NOTIFICATION)
			.then(({ data: data }) => {
				setUnreadNotifications(data?.totalUnreadNoty);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const removeNotitfication = (notificationId) => {
		axios
			.authDelete(REMOVE_NOTIFICATION + notificationId)
			.then(() => {
				getNotification({ page: 0 });
				setPage(0);
			})
			.catch((err) => {
				console.log(err);
			});
	};
	const clearAllNotitfication = () => {
		axios
			.authPost(CLEAR_ALL_NOTIFICATION)
			.then(() => {
				getNotification({ page: 0 });
				setPage(0);
			})
			.catch((err) => {
				console.log(err);
			});
	};
	const readNotitfication = () => {
		axios
			.authPost(READ_NOTIFICATION)
			.then(() => {
				if (userLogin) {
					getUnreadNotifications();
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleDropdownToggle = () => {
		setShow(!show);
		readNotitfication();
	};

	const fetchMoreData = () => {
		getNotification({ page: page + 1 });
		setPage((prev) => prev + 1);
	};

	const getAppSetting = () => {
		axios
			.authGet(GET_APP_SETTTING)
			.then(({ data: data }) => {
				dispatch(appSettings(data, "GET_APP_SETTINGS"));
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleLogOut = () => {
		localStorage.removeItem("access_token");
		dispatch(
			logout({
				token: "",
				user: "",
			})
		);
		dispatch(addMultiProduct({}, "ADD_RING"));
		dispatch(addMultiProduct({}, "ADD_DIAMOND"));
		dispatch(addMultiProductGemstone({}, "ADD_GEMSTONE"));
		dispatch(
			appSettings(
				{
					social_link: {
						facebook: "",
						twitter: "",
						instagram: "",
						linkedin: "",
						skype: "",
					},
					general: {
						cancel_days: "",
						logo: "",
						returnDays: "",
						company_name: "",
						deliveryDays: "",
						email: "",
						contact_number: "",
						address: "",
					},
					fixed_price: {
						engraving_price: "",
					},
				},
				"GET_APP_SETTINGS"
			)
		);
		dispatch(multiProductFlag("", "MULTIPRODUCT_FLAG"));
		dispatch(startWithGemstone("", "START_WITH_GEMSTONE"));
		dispatch(diamondSettingShowOrNot(false, "DELETE_DIAMOND_SETTING_SHOW_OR_NOT"));
		dispatch(makeJewellery("", "MAKE_JEWELLERY"));
		dispatch(allCategory([], "ALL_CATEGORY"));
		dispatch(wishListCount(0, "WISHLIST_COUNT"));
		dispatch(addToCartCount(0, "ADD_TO_CART_COUNT"));
		dispatch(multiProductSettingShowOrNot(false, "MULTI_PRODUCT_FILTER_SHOW_OR_NOT"));
	};

	const handleGemstone = () => {
		dispatch(multiProductFlag("", "MULTIPRODUCT_FLAG"));
		dispatch(startWithGemstone(1, "START_WITH_GEMSTONE"));
		dispatch(deleteMultiProductGemstone("delete", "DELETE_GEMSTONE"));
		dispatch(deleteMultiProductDiamond("delete", "DELETE_DIAMOND"));
		dispatch(deleteMultiProductRing("delete", "DELETE_RING"));
		dispatch(deleteMultiProductGemstone("delete", "DELETE_GEMSTONE"));
	};

	const handleGemstoneSetting = () => {
		dispatch(multiProductFlag(1, "MULTIPRODUCT_FLAG"));
		dispatch(deleteStartWithGemstone("", "DELETE_START_WITH_GEMSTONE"));
		dispatch(deleteMultiProductRing("delete", "DELETE_RING"));
		dispatch(deleteMultiProductDiamond("delete", "DELETE_DIAMOND"));
		dispatch(deleteMultiProductGemstone("delete", "DELETE_GEMSTONE"));
		dispatch(makeJewellery(" ", "MAKE_JEWELLERY"));
	};

	const handleJewellery = () => {
		dispatch(makeJewellery("", "MAKE_JEWELLERY"));
		dispatch(startWithGemstone("", "START_WITH_GEMSTONE"));
		dispatch(multiProductFlag("", "MULTIPRODUCT_FLAG"));
		dispatch(deleteMultiProductRing("delete", "DELETE_RING"));
		dispatch(deleteMultiProductDiamond("delete", "DELETE_DIAMOND"));
		dispatch(deleteMultiProductGemstone("delete", "DELETE_GEMSTONE"));
	};

	const handleHideAllMultiProductSelectionSetting = () => {
		dispatch(diamondSettingShowOrNot(false, "DELETE_DIAMOND_SETTING_SHOW_OR_NOT"));
		dispatch(deleteMultiProductDiamond("delete", "DELETE_DIAMOND"));
		dispatch(deleteMultiProductRing("delete", "DELETE_RING"));
		dispatch(makeJewellery("", "MAKE_JEWELLERY"));
		dispatch(deleteMultiProductGemstone("delete", "DELETE_GEMSTONE"));
		dispatch(multiProductFlag("", "MULTIPRODUCT_FLAG"));
	};

	const handlemakeJewellery = (data) => {
		if (data === "make Earrings") {
			dispatch(makeJewellery(data, "MAKE_JEWELLERY"));
			dispatch(startWithGemstone("", "START_WITH_GEMSTONE"));
			dispatch(deleteMultiProductRing("delete", "DELETE_RING"));
			dispatch(deleteMultiProductDiamond("delete", "DELETE_DIAMOND"));
			dispatch(deleteMultiProductGemstone("delete", "DELETE_GEMSTONE"));
		} else {
			dispatch(makeJewellery(data, "MAKE_JEWELLERY"));
			dispatch(startWithGemstone("", "START_WITH_GEMSTONE"));
			dispatch(deleteMultiProductRing("delete", "DELETE_RING"));
			dispatch(deleteMultiProductDiamond("delete", "DELETE_DIAMOND"));
			dispatch(deleteMultiProductGemstone("delete", "DELETE_GEMSTONE"));
		}
		dispatch(deleteMultiProductRing("delete", "DELETE_RING"));
		dispatch(deleteMultiProductDiamond("delete", "DELETE_DIAMOND"));
		dispatch(multiProductFlag("", "MULTIPRODUCT_FLAG"));
	};

	const removeProductArray = () => {
		dispatch(multiProductFlag(0, "MULTIPRODUCT_FLAG"));
		dispatch(deleteMultiProductRing("delete", "DELETE_RING"));
		dispatch(deleteMultiProductDiamond("delete", "DELETE_DIAMOND"));
		dispatch(startWithGemstone("", "START_WITH_GEMSTONE"));
		dispatch(makeJewellery(" ", "MAKE_JEWELLERY"));
	};

	const handleStartWithGemstoneNecklace = () => {
		dispatch(startWithGemstone("gemstoneNecklace", "START_WITH_GEMSTONE"));
		dispatch(deleteMultiProductGemstone("delete", "DELETE_GEMSTONE"));
		dispatch(makeJewellery(" ", "MAKE_JEWELLERY"));
		dispatch(multiProductFlag("", "MULTIPRODUCT_FLAG"));
		dispatch(deleteMultiProductRing("delete", "DELETE_RING"));
		dispatch(deleteMultiProductDiamond("delete", "DELETE_DIAMOND"));
	};

	const handleRemoveGemstone = () => {
		dispatch(deleteMultiProductGemstone("delete", "DELETE_GEMSTONE"));
		dispatch(multiProductFlag(0, "MULTIPRODUCT_FLAG"));
		dispatch(deleteMultiProductRing("delete", "DELETE_RING"));
		dispatch(deleteMultiProductDiamond("delete", "DELETE_DIAMOND"));
	};

	useEffect(() => {
		if (userLogin) {
			getWishlistTotal()
				.then((total) => {
					dispatch(wishListCount(total, "WISHLIST_COUNT"));
				})
				.catch((err) => {
					console.log(err);
				});

			getCartTotal()
				.then((addToCartTotal) => {
					dispatch(addToCartCount(addToCartTotal, "ADD_TO_CART_COUNT"));
				})
				.catch((err) => {
					console.log(err);
				});
		}

		getAppSetting();
	}, [userLogin]);

	useEffect(() => {
		const socket = io(API_BASE_URL);
		if (userName?.id) {
			socket.emit("room", userName?.id);
			socket.on("notification", () => {
				if (userLogin) {
					getUnreadNotifications();
					getNotification({ page: 0 });
				}
			});
		} else {
			socket.emit("room", "guest-customer");
		}

		return () => {
			socket.disconnect();
		};
	}, [userName?.id]);

	useEffect(() => {
		const isUserArrived = localStorage?.getItem("userArrived");
		if (!isUserArrived) {
			localStorage.setItem("userArrived", true);
			dispatch(addMultiProduct({}, "ADD_RING"));
			dispatch(addMultiProduct({}, "ADD_DIAMOND"));
			dispatch(addMultiProductGemstone({}, "ADD_GEMSTONE"));
			dispatch(
				logout({
					token: "",
					user: "",
				})
			);
			getAppSetting();
		}
		if (userLogin) {
			getUnreadNotifications();
			getNotification({ page: 0 });
		}

		getCategory();
	}, []);

	return (
		<>
			<header className="header-area header-wide">
				{width >= notShowMobileHeader && (
					<Container>
						<Row className="justify-content-center align-items-center">
							<Col xl={4}>
								<DynamicSearch />
							</Col>
							<Col xl={4}>
								<div className="logo text-center">
									<Link to="/">
										<img
											src={CONFIG.API_BASE_URL + logoImage?.general?.logo}
											alt="Brand Logo"
											onError={({ currentTarget }) => {
												currentTarget.onerror = null;
												currentTarget.src = "../../../../images/default-images/our-product.svg";
											}}
										/>
									</Link>
								</div>
							</Col>
							<Col xl={4}>
								<div className="header-right d-flex align-items-center justify-content-xl-center justify-content-lg-center">
									<div className="header-configure-area">
										<ul className="nav align-items-center justify-content-end">
											<li className="user-hover">
												<div className="user-name-div">
													{userLogin ? <p className="user-name my-2">{userName?.firstName + " " + userName?.lastName}</p> : ""}
													<i className="bi bi-person-circle"></i>
												</div>
												<ul className="dropdown-list">
													{userLogin ? (
														<li>
															<a href={ROUTES.MY_ACCOUNT} className="d-flex align-items-center">
																<i className="bi bi-person-fill me-2"></i>my account
															</a>
															<a href={"/"} className="d-flex align-items-center" onClick={handleLogOut}>
																<i className="bi bi-box-arrow-right me-2"></i>
																logOut
															</a>
														</li>
													) : (
														<li>
															<a onClick={() => setIsModelOpen(true)} style={{ cursor: "pointer" }} className="d-flex  align-items-center">
																<i className="bi bi-box-arrow-right me-2"></i>
																login
															</a>
														</li>
													)}
												</ul>
											</li>
											{userLogin ? (
												<li>
													<button
														onClick={() => {
															if (userLogin) {
																handleDropdownToggle();
															}
														}}
														className={`${unreadNotifications > 0 ? "notification--btn position-relative" : "unnotification--btn position-relative"}`}
													>
														<i className="bi bi-bell d-block"></i>
														<div className="notification notification_icon">{unreadNotifications}</div>
													</button>
												</li>
											) : (
												""
											)}
											<li>
												<a href={ROUTES.WHISLIST}>
													<i className="bi bi-heart d-block"></i>
													<div className="notification">{userLogin ? wishlistTotal : "0"}</div>
												</a>
											</li>
											<li>
												<a href={ROUTES.CART} className="minicart-btn">
													<i className="bi bi-bag d-block"></i>
													<div className="notification">{userLogin ? addToCartTotals : "0"}</div>
												</a>
											</li>
										</ul>
									</div>
								</div>
							</Col>

							<Col xl={8} className="mt-2">
								<div className="main-menu-area h-100">
									<div className="main-menu h-100">
										<nav className="desktop-menu h-100">
											<ul className="h-100">
												{headerLink.includes("Engagement Rings") ? (
													<li className="active position-static h-100">
														<>
															<a href={ROUTES.ENGAGEMENT_RINGS} className="h-100">
																ENGAGEMENT RINGS
																<i className="bi bi-chevron-down"></i>
															</a>
														</>

														<ul className="megamenu dropdown">
															<Container>
																<Row>
																	<Col xl={3}>
																		<div className="submenu-title">
																			<p className="subtitle-link">Create your own engagement ring</p>
																			<a
																				href="/engagement-rings/engagement-ring-settings"
																				className="subtitle-inner-link"
																				onClick={removeProductArray}
																			>
																				<i className="iconking-setting"></i>
																				Start with a Setting
																			</a>
																			<a href="/diamond/design-your-own-engagement-ring" className="subtitle-inner-link" onClick={removeProductArray}>
																				<i className="iconking-diamond"></i>
																				Start with a Diamond
																			</a>
																			<a
																				href="/diamond/design-your-own-lab-created-diamond-engagement-ring"
																				className="subtitle-inner-link"
																				onClick={removeProductArray}
																			>
																				<i className="iconking-lab-diamond"></i>
																				Start with a Lab Grown Diamond
																			</a>
																			<a href="/gemstone/shop-all" className="subtitle-inner-link" onClick={handleGemstone}>
																				<i className="iconking-gemstone"></i>
																				Start with a Gemstone
																			</a>
																			<a href="/engagement-rings/wedings-ring-sets" className="subtitle-inner-link" onClick={removeProductArray}>
																				<i className="iconking-bridal-set"></i>
																				Start with a Bridal Set
																			</a>
																		</div>
																	</Col>
																	<Col xl={3}>
																		<div className="submenu-title">
																			<p className="subtitle-link">Shop by shape</p>
																			<Row>
																				<Col xl={6}>
																					<a
																						href="/engagement-rings/round-engagement-rings"
																						className="subtitle-inner-link"
																						onClick={handleRemoveGemstone}
																					>
																						<i className="iconking-round"></i>
																						Round
																					</a>
																					<a
																						href="/engagement-rings/cushion-engagement-rings"
																						className="subtitle-inner-link"
																						onClick={handleRemoveGemstone}
																					>
																						<i className="iconking-cushion"></i>
																						Cushion
																					</a>
																					<a
																						href="/engagement-rings/pear-engagement-rings"
																						className="subtitle-inner-link"
																						onClick={handleRemoveGemstone}
																					>
																						<i className="iconking-pear"></i>
																						Pear
																					</a>
																					<a
																						href="/engagement-rings/princess-engagement-rings"
																						className="subtitle-inner-link"
																						onClick={handleRemoveGemstone}
																					>
																						<i className="iconking-princess"></i>
																						Princess
																					</a>
																					<a
																						href="/engagement-rings/asscher-engagement-rings"
																						className="subtitle-inner-link"
																						onClick={handleRemoveGemstone}
																					>
																						<i className="iconking-asscher"></i>
																						Asscher
																					</a>
																				</Col>
																				<Col xl={6}>
																					<a
																						href="/engagement-rings/oval-engagement-rings"
																						className="subtitle-inner-link"
																						onClick={handleRemoveGemstone}
																					>
																						<i className="iconking-oval"></i>
																						Oval
																					</a>
																					<a
																						href="/engagement-rings/emerald-engagement-rings"
																						className="subtitle-inner-link"
																						onClick={handleRemoveGemstone}
																					>
																						<i className="iconking-emerald"></i>
																						Emerald
																					</a>
																					<a
																						href="/engagement-rings/radiant-engagement-rings"
																						className="subtitle-inner-link"
																						onClick={handleRemoveGemstone}
																					>
																						<i className="iconking-radiant"></i>
																						Radiant
																					</a>
																					<a
																						href="/engagement-rings/marquise-engagement-rings"
																						className="subtitle-inner-link"
																						onClick={handleRemoveGemstone}
																					>
																						<i className="iconking-marquise"></i>
																						Marquise
																					</a>
																					<a
																						href="/engagement-rings/heart-engagement-rings"
																						className="subtitle-inner-link"
																						onClick={handleRemoveGemstone}
																					>
																						<i className="iconking-heart"></i>
																						Heart
																					</a>
																				</Col>
																			</Row>
																		</div>
																	</Col>
																	<Col xl={2}>
																		<div className="submenu-title">
																			<p className="subtitle-link">engagement Ring style</p>
																			<a href="/engagement-rings/Solitaire" className="subtitle-inner-link " onClick={handleRemoveGemstone}>
																				Solitaire
																			</a>
																			<a href="/engagement-rings/Three Stone" className="subtitle-inner-link" onClick={handleRemoveGemstone}>
																				Three stone
																			</a>
																			<a href="/engagement-rings/Hidden Halo" className="subtitle-inner-link" onClick={handleRemoveGemstone}>
																				Hidden halo
																			</a>
																			<a href="/engagement-rings/Halo" className="subtitle-inner-link" onClick={handleRemoveGemstone}>
																				Halo
																			</a>
																		</div>
																	</Col>
																	<Col xl={2}>
																		<div className="submenu-title">
																			<p className="subtitle-link">Gemstone engagement ring</p>
																			<a href="/gemstone/moissanite" className="subtitle-inner-link" onClick={handleGemstone}>
																				Moissanite Rings
																			</a>
																			<a href="/gemstone/sapphire" className="subtitle-inner-link" onClick={handleGemstone}>
																				Sapphire Rings
																			</a>
																			<a href="/gemstone/emerald" className="subtitle-inner-link" onClick={handleGemstone}>
																				Emerald Rings
																			</a>
																			<a href="/gemstone/aquamarine" className="subtitle-inner-link" onClick={handleGemstone}>
																				Aquamarine Rings
																			</a>
																			<a href="/gemstone/morganite" className="subtitle-inner-link" onClick={handleGemstone}>
																				Morganite Rings
																			</a>
																		</div>
																	</Col>
																	<Col xl={2}>
																		<img src="../../../../images/engagement-rings.webp" alt="..." className="header-img" />
																	</Col>
																</Row>
															</Container>
														</ul>
													</li>
												) : (
													""
												)}
												{headerLink.includes("Wedding Rings") ? (
													<li className="position-static h-100">
														<>
															<a href={ROUTES.WEDDING_RINGS} className="h-100">
																WEDDING RINGS
																<i className="bi bi-chevron-down"></i>
															</a>
														</>

														<ul className="megamenu dropdown">
															<Container>
																<Row className="justify-content-center">
																	<Col xl={2} lg={3}>
																		<div className="submenu-title">
																			<p className="subtitle-link">WOMEN</p>
																			<a href="/rings/women-wedding-rings" className="subtitle-inner-link">
																				Women's Wedding Rings
																			</a>
																			<a href="/rings/women-diamond-rings" className="subtitle-inner-link">
																				Diamond Rings
																			</a>
																			<a href="/rings/women-eternity-rings" className="subtitle-inner-link">
																				Eternity Rings
																			</a>
																			<a href="/rings/women-anniversary-rings" className="subtitle-inner-link">
																				Anniversary Rings
																			</a>
																			<a href="/rings/women-curved-rings" className="subtitle-inner-link">
																				Curved Rings
																			</a>
																		</div>
																	</Col>
																	<Col xl={2} lg={3}>
																		<div className="submenu-title">
																			<p className="subtitle-link">MEN</p>
																			<a href="/rings/mens-wedding-rings" className="subtitle-inner-link">
																				Men's Wedding Rings
																			</a>
																			<a href="/rings/mens-classic-wedding-rings" className="subtitle-inner-link">
																				Classic Rings
																			</a>
																			<a href="/rings/mens-engagement-rings" className="subtitle-inner-link">
																				Men's Engagement Rings
																			</a>

																			<a href="/rings/mens-matte-wedding-rings" className="subtitle-inner-link">
																				Matte Rings
																			</a>
																			<a href="/rings/mens-diamond-wedding-rings" className="subtitle-inner-link">
																				Diamonds and Gemstones
																			</a>
																			<a href="/rings/mens-hammered-wedding-rings" className="subtitle-inner-link">
																				Textured Rings
																			</a>
																			<a href="/jewellery/mens/shop-all" className="subtitle-inner-link" onClick={handleJewellery}>
																				Men's Jewelry
																			</a>
																		</div>
																	</Col>
																	<Col xl={2} lg={3}>
																		<div className="submenu-title">
																			<p className="subtitle-link">MEN'S BY METAL</p>
																			<a href="/rings/mens-platinum-wedding-bands" className="subtitle-inner-link">
																				Platinum
																			</a>
																			<a href="/rings/mens-silver-wedding-bands" className="subtitle-inner-link">
																				Silver
																			</a>
																			<a href="/rings/mens-yellow-gold-wedding-bands" className="subtitle-inner-link">
																				Yellow Gold
																			</a>
																			<a href="/rings/mens-white-gold-wedding-bands" className="subtitle-inner-link">
																				White Gold
																			</a>
																			<a href="/rings/mens-rose-gold-wedding-bands" className="subtitle-inner-link">
																				Rose Gold
																			</a>
																		</div>
																	</Col>
																	<Col xl={2} lg={3}>
																		<div className="submenu-title">
																			<p className="subtitle-link">WOMEN'S BY METAL</p>
																			<a href="/rings/women-platinum-wedding-bands" className="subtitle-inner-link">
																				Platinum
																			</a>
																			<a href="/rings/women-silver-wedding-bands" className="subtitle-inner-link">
																				Silver
																			</a>
																			<a href="/rings/women-yellow-gold-wedding-bands" className="subtitle-inner-link">
																				Yellow Gold
																			</a>
																			<a href="/rings/women-white-gold-wedding-bands" className="subtitle-inner-link">
																				White Gold
																			</a>
																			<a href="/rings/women-rose-gold-wedding-bands" className="subtitle-inner-link">
																				Rose Gold
																			</a>
																		</div>
																	</Col>
																	<Col xl={2} lg={3}>
																		<img src="../../../../images/header-img/header-img-1.webp" alt="..." className="header-img" />
																	</Col>
																</Row>
															</Container>
														</ul>
													</li>
												) : (
													""
												)}
												{headerLink.includes("Diamond") ? (
													<li className="position-static h-100">
														<>
															<a href={ROUTES.DIAMOND_PAGE} className="h-100">
																DIAMONDS <i className="bi bi-chevron-down"></i>
															</a>
														</>

														<ul className="megamenu dropdown">
															<Container>
																<Row className="justify-content-center">
																	<Col xl={2} lg={3}>
																		<div className="submenu-title">
																			<p className="subtitle-link">NATURAL DIAMONDS</p>
																			<a href="/diamond/natural" className="subtitle-inner-link" onClick={handleHideAllMultiProductSelectionSetting}>
																				Search Natural Diamonds
																			</a>
																			<Row>
																				<Col xl={6}>
																					<a
																						href="/diamond/round"
																						className="subtitle-inner-link"
																						onClick={handleHideAllMultiProductSelectionSetting}
																					>
																						<i className="iconking-round"></i>
																						Round
																					</a>

																					<a
																						href="/diamond/cushion"
																						className="subtitle-inner-link"
																						onClick={handleHideAllMultiProductSelectionSetting}
																					>
																						<i className="iconking-cushion"></i>
																						Cushion
																					</a>
																					<a href="/diamond/pear" className="subtitle-inner-link" onClick={handleHideAllMultiProductSelectionSetting}>
																						<i className="iconking-pear"></i>
																						Pear
																					</a>
																					<a
																						href="/diamond/princess"
																						className="subtitle-inner-link"
																						onClick={handleHideAllMultiProductSelectionSetting}
																					>
																						<i className="iconking-princess"></i>
																						Princess
																					</a>
																					<a
																						href="/diamond/asscher"
																						className="subtitle-inner-link"
																						onClick={handleHideAllMultiProductSelectionSetting}
																					>
																						<i className="iconking-asscher"></i>
																						Asscher
																					</a>
																				</Col>
																				<Col xl={6}>
																					<a href="/diamond/oval" className="subtitle-inner-link" onClick={handleHideAllMultiProductSelectionSetting}>
																						<i className="iconking-oval"></i>
																						Oval
																					</a>
																					<a
																						href="/diamond/emerald"
																						className="subtitle-inner-link"
																						onClick={handleHideAllMultiProductSelectionSetting}
																					>
																						<i className="iconking-emerald"></i>
																						Emerald
																					</a>
																					<a
																						href="/diamond/radiant"
																						className="subtitle-inner-link"
																						onClick={handleHideAllMultiProductSelectionSetting}
																					>
																						<i className="iconking-radiant"></i>
																						Radiant
																					</a>
																					<a
																						href="/diamond/marquise"
																						className="subtitle-inner-link"
																						onClick={handleHideAllMultiProductSelectionSetting}
																					>
																						<i className="iconking-marquise"></i>
																						Marquise
																					</a>
																					<a
																						href="/diamond/heart"
																						className="subtitle-inner-link"
																						onClick={handleHideAllMultiProductSelectionSetting}
																					>
																						<i className="iconking-heart"></i>
																						Heart
																					</a>
																				</Col>
																			</Row>
																			<a href="/diamond/colored" className="subtitle-inner-link" onClick={handleHideAllMultiProductSelectionSetting}>
																				Colored Diamonds
																			</a>
																			<a
																				href="/diamond/super-ideal-cut"
																				className="subtitle-inner-link"
																				onClick={handleHideAllMultiProductSelectionSetting}
																			>
																				Super Ideal Diamonds
																			</a>
																		</div>
																	</Col>
																	<Col xl={2} lg={3}>
																		<div className="submenu-title">
																			<p className="subtitle-link">LAB DIAMONDS</p>
																			<a
																				href="/diamond/lab-diamonds-search"
																				className="subtitle-inner-link"
																				onClick={handleHideAllMultiProductSelectionSetting}
																			>
																				Search Lab Diamonds
																			</a>
																			<a
																				href="/diamond/lab-created-colored-diamonds"
																				className="subtitle-inner-link"
																				onClick={handleHideAllMultiProductSelectionSetting}
																			>
																				Lab Colored Diamonds
																			</a>
																		</div>
																	</Col>
																	<Col xl={2} lg={3}>
																		<div className="submenu-title">
																			<p className="subtitle-link">CREATE YOUR OWN</p>
																			<a href="/diamond/design-your-own-engagement-ring" className="subtitle-inner-link" onClick={removeProductArray}>
																				Create Your Own Diamond Ring
																			</a>
																			<a
																				href="/diamond/design-your-own-lab-created-diamond-engagement-ring"
																				className="subtitle-inner-link"
																				onClick={removeProductArray}
																			>
																				Create Your Own Lab Diamond Ring
																			</a>
																			<a
																				href="/jewellery/Earrings/shop-all"
																				className="subtitle-inner-link"
																				onClick={() => handlemakeJewellery("make Earrings")}
																			>
																				Create Your Own Diamond Earrings
																			</a>
																			<a
																				href="/jewellery/Necklaces/shop-all"
																				className="subtitle-inner-link"
																				onClick={() => handlemakeJewellery("make Necklaces")}
																			>
																				Create Your Own Diamond Necklace
																			</a>
																		</div>
																	</Col>
																	<Col xl={2} lg={3}>
																		<img src="../../../../images/header-img/header-img-2.webp" alt="..." className="header-img" />
																	</Col>
																</Row>
															</Container>
														</ul>
													</li>
												) : (
													""
												)}
												{headerLink.includes("Gemstone") ? (
													<li className="position-static h-100">
														<>
															<a href={ROUTES.GEMSTONES} className="h-100">
																GEMSTONES <i className="bi bi-chevron-down"></i>
															</a>
														</>

														<ul className="megamenu dropdown">
															<Container>
																<Row>
																	<Col xl={3} lg={3}>
																		<div className="submenu-title">
																			<p className="subtitle-link">CREATE YOUR OWN GEMSTONE RING</p>
																			<a
																				href="/engagement-rings/gemstone-engagement-ring-settings"
																				className="subtitle-inner-link"
																				onClick={handleGemstoneSetting}
																			>
																				<i className="iconking-setting"></i>
																				Start with a Setting
																			</a>
																			<a href="/gemstone/shop-all" className="subtitle-inner-link" onClick={handleGemstone}>
																				<i className="iconking-gemstone"></i>
																				Start with a Gemstone
																			</a>
																			<a href="/gemstone/moissanite" className="subtitle-inner-link" onClick={handleGemstone}>
																				<i className="iconking-moissanite"></i>
																				moissanite
																			</a>
																			<a href="/gemstone/sapphire" className="subtitle-inner-link" onClick={handleGemstone}>
																				<i className="iconking-sapphire"></i>
																				sapphire
																			</a>
																			<a href="/gemstone/emerald" className="subtitle-inner-link" onClick={handleGemstone}>
																				<i className="iconking-emerald-2"></i>
																				Emerald
																			</a>
																			<a href="/gemstone/alexandrite" className="subtitle-inner-link" onClick={handleGemstone}>
																				<i className="iconking-alexandrite"></i>
																				alexandrite
																			</a>
																			<a href="/gemstone/aquamarine" className="subtitle-inner-link" onClick={handleGemstone}>
																				<i className="iconking-aquamarine"></i>
																				aquamarine
																			</a>
																			<a href="/gemstone/morganite" className="subtitle-inner-link" onClick={handleGemstone}>
																				<i className="iconking-morganite"></i>
																				Morganite
																			</a>
																			<a href="/gemstone/ruby" className="subtitle-inner-link" onClick={handleGemstone}>
																				<i className="iconking-ruby"></i>
																				Ruby
																			</a>
																			<a href="/gemstone/colored-diamond" className="subtitle-inner-link" onClick={handleGemstone}>
																				<i className="iconking-colored-diamond"></i>
																				Colored Diamond
																			</a>
																		</div>
																	</Col>
																	<Col xl={2} lg={3}>
																		<div className="submenu-title">
																			<p className="subtitle-link">SHOP BY COLOR</p>
																			<a href="/gemstone/blue" className="subtitle-inner-link" onClick={handleGemstone}>
																				Blue
																			</a>
																			<a href="/gemstone/teal" className="subtitle-inner-link" onClick={handleGemstone}>
																				Teal
																			</a>
																			<a href="/gemstone/green" className="subtitle-inner-link" onClick={handleGemstone}>
																				Green
																			</a>
																			<a href="/gemstone/pink" className="subtitle-inner-link" onClick={handleGemstone}>
																				Pink
																			</a>
																			<a href="/gemstone/purple" className="subtitle-inner-link" onClick={handleGemstone}>
																				Purple
																			</a>
																			<a href="/gemstone/peach" className="subtitle-inner-link" onClick={handleGemstone}>
																				Peach
																			</a>
																		</div>
																	</Col>
																	<Col xl={2} lg={3}>
																		<div className="submenu-title">
																			<p className="subtitle-link">SHOP BY SHAPE</p>
																			<a href="/gemstone/oval" className="subtitle-inner-link" onClick={handleGemstone}>
																				Oval
																			</a>
																			<a href="/gemstone/round" className="subtitle-inner-link" onClick={handleGemstone}>
																				Round
																			</a>
																			<a href="/gemstone/emerald-shape" className="subtitle-inner-link" onClick={handleGemstone}>
																				Emerald
																			</a>
																			<a href="/gemstone/cushion" className="subtitle-inner-link" onClick={handleGemstone}>
																				Cushion
																			</a>
																			<a href="/gemstone/radiant" className="subtitle-inner-link" onClick={handleGemstone}>
																				Radiant
																			</a>
																		</div>
																	</Col>
																	<Col xl={3} lg={3}>
																		<div className="submenu-title">
																			<p className="subtitle-link">PRESET GEMSTONE RINGS</p>
																			<a href="/engagement-rings/moissanite-rings" className="subtitle-inner-link">
																				Moissanite Rings
																			</a>
																			<a href="/engagement-rings/sapphire-rings" className="subtitle-inner-link">
																				Sapphire Rings
																			</a>
																			<a href="/engagement-rings/aquamarine-rings" className="subtitle-inner-link">
																				Aquamarine Rings
																			</a>
																			<a href="/engagement-rings/morganite-rings" className="subtitle-inner-link">
																				Morganite Rings
																			</a>
																		</div>
																	</Col>
																	<Col xl={2} lg={3}>
																		<img src="../../../../images/header-img/header-img-3.webp" alt="..." className="header-img" />
																	</Col>
																</Row>
															</Container>
														</ul>
													</li>
												) : (
													""
												)}
												{headerLink.includes("Jewellery") ? (
													<li className="position-static h-100">
														<>
															<a href={ROUTES.JEWELRY_PAGE} className="h-100">
																JEWELRY <i className="bi bi-chevron-down"></i>
															</a>
														</>

														<ul className="megamenu dropdown">
															<Container>
																<Row className="justify-content-center">
																	<Col xl={2} lg={3}>
																		<div className="submenu-title">
																			<p className="subtitle-link">JEWELRY</p>
																			<a href="/jewellery/Rings/shop-all" className="subtitle-inner-link" onClick={handleJewellery}>
																				Rings
																			</a>
																			<a href="/jewellery/Earrings/shop-all" className="subtitle-inner-link" onClick={handleJewellery}>
																				Earrings
																			</a>
																			<a href="/jewellery/Necklaces/shop-all" className="subtitle-inner-link" onClick={handleJewellery}>
																				Necklaces
																			</a>
																			<a href="/jewellery/Bracelets/shop-all" className="subtitle-inner-link" onClick={handleJewellery}>
																				Bracelets
																			</a>
																			<a href="/jewellery/mens/shop-all" className="subtitle-inner-link" onClick={handleJewellery}>
																				Men's Jewelry
																			</a>
																			<a href="/jewellery/new-arrivals/shop-all" className="subtitle-inner-link" onClick={handleJewellery}>
																				New Arrivals
																			</a>
																			<a href="/jewellery/all-category/shop-all" onClick={handleJewellery} className="subtitle-inner-link m-0">
																				Shop All Jewelry
																			</a>
																		</div>
																	</Col>
																	<Col xl={2} lg={3}>
																		<div className="submenu-title">
																			<p className="subtitle-link">SHOP BY STYLE</p>
																			<a
																				href="/jewellery/lab-diamond-jewelry/shop-by-style"
																				onClick={handleJewellery}
																				className="subtitle-inner-link"
																			>
																				Lab Diamond Jewelry
																			</a>
																			<a href="/jewellery/Diamond Studs/shop-by-style" className="subtitle-inner-link" onClick={handleJewellery}>
																				Diamond Stud Earrings
																			</a>
																			<a href="/jewellery/birthstone-jewelry/shop-by-style" onClick={handleJewellery} className="subtitle-inner-link">
																				Birthstone Jewelry
																			</a>
																			<a href="/jewellery/Tennis Bracelets/shop-by-style" onClick={handleJewellery} className="subtitle-inner-link">
																				Tennis Bracelets
																			</a>
																			<a href="/jewellery/gemstone-jewelry/shop-by-style" onClick={handleJewellery} className="subtitle-inner-link">
																				Gemstone Jewelry
																			</a>
																			<a href="/jewellery/diamond-jewelry/shop-by-style" onClick={handleJewellery} className="subtitle-inner-link">
																				Diamond Jewelry
																			</a>
																			<a href="/jewellery/tennis-jewelry/shop-by-style" onClick={handleJewellery} className="subtitle-inner-link">
																				Tennis Necklaces
																			</a>
																		</div>
																	</Col>
																	<Col xl={2} lg={3}>
																		<div className="submenu-title">
																			<p className="subtitle-link">CREATE YOUR OWN</p>
																			<a
																				href="/jewellery/Earrings/shop-all"
																				className="subtitle-inner-link"
																				onClick={() => handlemakeJewellery("make Earrings")}
																			>
																				Diamond Earrings
																			</a>
																			<a
																				href="/jewellery/Necklaces/shop-all"
																				className="subtitle-inner-link"
																				onClick={() => handlemakeJewellery("make Necklaces")}
																			>
																				Diamond Necklace
																			</a>
																			<a
																				href="/gemstone/design-your-own-necklace"
																				className="subtitle-inner-link"
																				onClick={handleStartWithGemstoneNecklace}
																			>
																				Gemstone Necklace
																			</a>
																			<a href="/diamond/design-your-own-engagement-ring" className="subtitle-inner-link" onClick={removeProductArray}>
																				Diamond Ring
																			</a>
																			<a href="/gemstone/shop-all" className="subtitle-inner-link" onClick={handleGemstone}>
																				Gemstone Ring
																			</a>
																		</div>
																	</Col>
																	<Col xl={2} lg={3}>
																		<img src="../../../../images/header-img/header-img-4.webp" alt="..." className="header-img" />
																	</Col>
																</Row>
															</Container>
														</ul>
													</li>
												) : (
													""
												)}
												{headerLink.includes("Gift") ? (
													<li className="position-static h-100">
														<>
															<a href={ROUTES.GIFTS} className="h-100">
																GIFTS <i className="bi bi-chevron-down"></i>
															</a>
														</>

														<ul className="megamenu dropdown">
															<Container>
																<Row>
																	<Col xl={2} lg={3}>
																		<div className="submenu-title">
																			<p className="subtitle-link">TOP GIFTS</p>
																			<a href="/jewellery/Diamond Studs/shop-by-style" className="subtitle-inner-link" onClick={handleJewellery}>
																				Diamond Stud Earrings
																			</a>
																			<a
																				href="/engagement-rings/gemstone-engagement-ring-settings"
																				onClick={handleGemstoneSetting}
																				className="subtitle-inner-link"
																			>
																				Gemstone Rings
																			</a>

																			<a href="/jewellery/Tennis Bracelets/shop-by-style" onClick={handleJewellery} className="subtitle-inner-link">
																				Tennis Bracelets
																			</a>
																			<a href="/jewellery/Earrings/Hoop Earrings" onClick={handleJewellery} className="subtitle-inner-link">
																				Hoop Earrings
																			</a>
																			<a href="/jewellery/all-category/gold-jewellery" onClick={handleJewellery} className="subtitle-inner-link">
																				Gold Jewelry
																			</a>
																		</div>
																	</Col>
																	<Col xl={2} lg={3}>
																		<div className="submenu-title">
																			<p className="subtitle-link">GIFTS BY RECIPIENT</p>
																			<a href="/jewellery/all-category/gifts-for-her" onClick={handleJewellery} className="subtitle-inner-link">
																				Gifts For Her
																			</a>
																			<a href="/jewellery/all-category/gifts-for-him" onClick={handleJewellery} className="subtitle-inner-link">
																				Gifts For Him
																			</a>
																			<a href="/jewellery/all-category/gifts-for-everyone" onClick={handleJewellery} className="subtitle-inner-link">
																				Gifts For Everyone
																			</a>
																		</div>
																	</Col>
																	<Col xl={2} lg={3}>
																		<div className="submenu-title">
																			<p className="subtitle-link">CREATE YOUR OWN</p>
																			<a
																				href="/jewellery/Earrings/shop-all"
																				className="subtitle-inner-link"
																				onClick={() => handlemakeJewellery("make Earrings")}
																			>
																				Diamond Earrings
																			</a>

																			<a
																				href="/jewellery/Necklaces/shop-all"
																				className="subtitle-inner-link"
																				onClick={() => handlemakeJewellery("make Necklaces")}
																			>
																				Diamond Necklace
																			</a>

																			<a
																				href="/gemstone/design-your-own-necklace"
																				className="subtitle-inner-link"
																				onClick={handleStartWithGemstoneNecklace}
																			>
																				Gemstone Necklace
																			</a>
																		</div>
																	</Col>
																	<Col xl={2} lg={3}>
																		<div className="submenu-title">
																			<p className="subtitle-link">MORE GIFT IDEAS</p>
																			<a
																				href="/jewellery/all-category/gifts-under-250"
																				className="subtitle-inner-link"
																				onClick={handleHideAllMultiProductSelectionSetting}
																			>
																				Gifts Under $250
																			</a>
																			<a
																				href="/jewellery/all-category/gifts-under-500"
																				className="subtitle-inner-link"
																				onClick={handleHideAllMultiProductSelectionSetting}
																			>
																				Gifts Under $500
																			</a>
																		</div>
																	</Col>
																	<Col xl={2} lg={3}>
																		<img src="../../../../images/header-img/header-img-6.webp" alt="..." className="header-img" />
																	</Col>
																</Row>
															</Container>
														</ul>
													</li>
												) : (
													""
												)}
												{headerLink.includes("About") ? (
													<li className="position-static h-100">
														<>
															<a href={ROUTES.ABOUT_US} className="h-100">
																ABOUT <i className="bi bi-chevron-down"></i>
															</a>
														</>

														<ul className="megamenu dropdown">
															<Container>
																<Row className="justify-content-center">
																	<Col xl={2} lg={3}>
																		<div className="submenu-title">
																			<a href={ROUTES.ABOUT_US} className="subtitle-link">
																				ABOUT US
																			</a>
																			<a href={ROUTES.RETURN_POLICY} className="subtitle-inner-link">
																				Return Policy
																			</a>
																			<a href={ROUTES.TERMS_AND_CONDITIONS} className="subtitle-inner-link">
																				Terms & Conditions
																			</a>
																			<a href={ROUTES.FAQ_PAGE} className="subtitle-inner-link">
																				FAQ'S
																			</a>
																			<a href={ROUTES.PRIVACY_POLICY} className="subtitle-inner-link">
																				Privacy Policy
																			</a>
																			<a href={ROUTES.SHIPPING_POLICY} className="subtitle-inner-link">
																				Shipping Policy
																			</a>
																			<a href={ROUTES.BLOG_PAGE} className="subtitle-inner-link">
																				Blog
																			</a>
																			<a href={ROUTES.CONTACT_US} className="subtitle-inner-link">
																				Contact Us
																			</a>
																		</div>
																	</Col>

																	<Col xl={2}>
																		<img src="../../../../images/header-img/header-img-5.webp" alt="..." className="header-img" />
																	</Col>
																</Row>
															</Container>
														</ul>
													</li>
												) : (
													""
												)}
											</ul>
										</nav>
									</div>
								</div>
							</Col>
						</Row>
					</Container>
				)}
				{width <= showMobileHeader && (
					<div className="mobile-header  d-md-block sticky">
						<div className="container-fluid">
							<div className="row align-items-center">
								<div className="col-12">
									<div className="mobile-main-header">
										<div className="mobile-logo">
											<a href="/">
												<img src="../../../../images/logo.png" alt="Brand Logo" />
											</a>
										</div>
										<div className="mobile-menu-toggler">
											{userLogin ? (
												<button
													onClick={() => {
														if (userLogin) {
															handleDropdownToggle();
														}
													}}
													className={`${unreadNotifications > 0 ? "notification--btn position-relative" : "unnotification--btn position-relative"}`}
												>
													<i className="bi bi-bell d-block"></i>
													<div className="notification notification_icon">{unreadNotifications}</div>
												</button>
											) : (
												""
											)}

											<div className="mini-cart-wrap">
												<a href="/cart">
													<i className="bi bi-bag d-block"></i>
													<div className="notification">{addToCartTotals || "0"}</div>
												</a>
											</div>
											<div className="mini-cart-heart">
												<a href="/wishlist">
													<i className="bi bi-heart d-block"></i>
													<div className="notification">{wishlistTotal || "0"}</div>
												</a>
											</div>
											<button
												className={`${isOpen ? "mobile-menu-btn-1" : "mobile-menu-btn"}`}
												onClick={() => {
													setIsOpen(!isOpen);
												}}
											>
												<span />
												<span />
												<span />
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}

				{width <= showMobileHeader && (
					<Offcanvas className="mobile-sidebar" show={isOpen} onHide={() => setIsOpen(false)}>
						<Offcanvas.Header className="justify-content-between mt-xl-3">
							<DynamicSearch />
							<button type="button" onClick={() => setIsOpen(false)}>
								<i className="bi bi-x-lg text-dark"></i>
							</button>
						</Offcanvas.Header>
						<Offcanvas.Body>
							<Accordion>
								{headerLink.includes("Engagement Rings") && (
									<Accordion.Item eventKey="0">
										<Accordion.Header>ENGAGEMENT RINGS</Accordion.Header>
										<Accordion.Body>
											<ul className="header-link">
												<Row>
													<Col xl={6}>
														<li>
															<a href="/engagement-rings" className="d-block header-title-link-header text-center">
																ENGAGEMENT RINGS
															</a>
															<p className="d-block header-title-link">Create your own engagement ring</p>
															<ul className="mt-2 sub-header-link">
																<li>
																	<a href="/engagement-rings/engagement-ring-settings" className="d-block" onClick={removeProductArray}>
																		Start with a Setting
																	</a>
																</li>
																<li>
																	<a href="/diamond/design-your-own-engagement-ring" className="d-block" onClick={removeProductArray}>
																		Start with a Diamond
																	</a>
																</li>
																<li>
																	<a
																		href="/diamond/design-your-own-lab-created-diamond-engagement-ring"
																		className="d-block"
																		onClick={removeProductArray}
																	>
																		Start with a Lab Grown Diamond
																	</a>
																</li>
																<li>
																	<a href="/gemstone/shop-all" className="d-block" onClick={handleGemstone}>
																		Start with a Gemstone
																	</a>
																</li>
																<li>
																	<a href="/engagement-rings/wedings-ring-sets" className="d-block" onClick={removeProductArray}>
																		Start with a Bridal Set
																	</a>
																</li>
															</ul>
														</li>
													</Col>
													<Col xl={6}>
														<li className="my-2">
															<p className="header-title-link">Shop by shape</p>
															<div className="d-flex align-items-center w-100 mt-2">
																<div className="w-50">
																	<ul className="mt-2 sub-header-link">
																		<li>
																			<a href="/engagement-rings/round-engagement-rings" className="d-block" onClick={handleRemoveGemstone}>
																				Round
																			</a>
																		</li>
																		<li>
																			<a href="/engagement-rings/cushion-engagement-rings" className="d-block" onClick={handleRemoveGemstone}>
																				Cushion
																			</a>
																		</li>
																		<li>
																			<a href="/engagement-rings/pear-engagement-rings" className="d-block" onClick={handleRemoveGemstone}>
																				Pear
																			</a>
																		</li>
																		<li>
																			<a href="/engagement-rings/princess-engagement-rings" className="d-block" onClick={handleRemoveGemstone}>
																				Princess
																			</a>
																		</li>
																		<li>
																			<a href="/engagement-rings/asscher-engagement-rings" className="d-block" onClick={handleRemoveGemstone}>
																				Asscher
																			</a>
																		</li>
																	</ul>
																</div>
																<div className="w-50">
																	<ul className="mt-2 sub-header-link">
																		<li>
																			<a href="/engagement-rings/oval-engagement-rings" className="d-block" onClick={handleRemoveGemstone}>
																				Oval
																			</a>
																		</li>
																		<li>
																			<a href="/engagement-rings/emerald-engagement-rings" className="d-block" onClick={handleRemoveGemstone}>
																				Emerald
																			</a>
																		</li>
																		<li>
																			<a href="/engagement-rings/radiant-engagement-rings" className="d-block" onClick={handleRemoveGemstone}>
																				Radiant
																			</a>
																		</li>
																		<li>
																			<a href="/engagement-rings/marquise-engagement-rings" className="d-block" onClick={handleRemoveGemstone}>
																				Marquise
																			</a>
																		</li>
																		<li>
																			<a href="/engagement-rings/heart-engagement-rings" className="d-block" onClick={handleRemoveGemstone}>
																				Heart
																			</a>
																		</li>
																	</ul>
																</div>
															</div>
														</li>
													</Col>
													<Col xl={6}>
														<li className="mb-2">
															<p className="d-block header-title-link">engagement Ring style</p>
															<ul className="mt-2 sub-header-link">
																<li>
																	<a href="/engagement-rings/Solitaire" className="d-block" onClick={handleRemoveGemstone}>
																		Solitaire
																	</a>
																</li>
																<li>
																	<a href="/engagement-rings/Three Stone" className="d-block" onClick={handleRemoveGemstone}>
																		Three stone
																	</a>
																</li>
																<li>
																	<a href="/engagement-rings/Hidden Halo" className="d-block" onClick={handleRemoveGemstone}>
																		Hidden halo
																	</a>
																</li>
																<li>
																	<a href="/engagement-rings/Halo" className="d-block" onClick={handleRemoveGemstone}>
																		Halo
																	</a>
																</li>
															</ul>
														</li>
													</Col>
													<Col xl={6}>
														<li>
															<p className="d-block header-title-link">Gemstone engagement ring</p>
															<ul className="mt-2 sub-header-link">
																<li>
																	<a href="/gemstone/moissanite" className="d-block" onClick={handleGemstone}>
																		Moissanite Rings
																	</a>
																</li>
																<li>
																	<a href="/gemstone/sapphire" className="d-block" onClick={handleGemstone}>
																		Sapphire Rings
																	</a>
																</li>
																<li>
																	<a href="/gemstone/emerald" className="d-block" onClick={handleGemstone}>
																		Emerald Rings
																	</a>
																</li>
																<li>
																	<a href="/gemstone/aquamarine" className="d-block" onClick={handleGemstone}>
																		Aquamarine Rings
																	</a>
																</li>
																<li>
																	<a href="/gemstone/morganite" className="d-block" onClick={handleGemstone}>
																		Morganite Rings
																	</a>
																</li>
															</ul>
														</li>
													</Col>
												</Row>
											</ul>
										</Accordion.Body>
									</Accordion.Item>
								)}
								{headerLink.includes("Wedding Rings") && (
									<Accordion.Item eventKey="1">
										<Accordion.Header>WEDDING RINGS</Accordion.Header>
										<Accordion.Body>
											<ul className="header-link">
												<Row>
													<Col xl={6}>
														<li>
															<a href="/wedding-rings" className="d-block header-title-link-header ">
																WEDDING RINGS
															</a>
															<p className="d-block mb-2 header-title-link">WOMEN</p>
															<ul className="sub-header-link">
																<li>
																	<a href="/rings/women-wedding-rings" className="d-block">
																		Women's Wedding Rings
																	</a>
																</li>
																<li>
																	<a href="/rings/women-diamond-rings" className="d-block">
																		Diamond Rings
																	</a>
																</li>

																<li>
																	<a href="/rings/women-eternity-rings" className="d-block">
																		Eternity Rings
																	</a>
																</li>
																<li>
																	<a href="/rings/women-anniversary-rings" className="d-block">
																		Anniversary Rings
																	</a>
																</li>
																<li>
																	<a href="/rings/women-curved-rings" className="d-block">
																		Curved Rings
																	</a>
																</li>
															</ul>
														</li>
													</Col>
													<Col xl={6}>
														<li className="my-3">
															<p className="d-block mb-2 header-title-link">MEN</p>
															<ul className="sub-header-link">
																<li>
																	<a href="/rings/mens-wedding-rings" className="d-block">
																		Men's Wedding Rings
																	</a>
																</li>
																<li>
																	<a href="/rings/mens-classic-wedding-rings" className="d-block">
																		Classic Rings
																	</a>
																</li>
																<li>
																	<a href="/rings/mens-engagement-rings" className="d-block">
																		Men's Engagement Rings
																	</a>
																</li>

																<li>
																	<a href="/rings/mens-matte-wedding-rings" className="d-block">
																		Matte Rings
																	</a>
																</li>
																<li>
																	<a href="/rings/mens-diamond-wedding-rings" className="d-block">
																		Diamonds and Gemstones
																	</a>
																</li>
																<li>
																	<a href="/rings/mens-hammered-wedding-rings" className="d-block">
																		Textured Rings
																	</a>
																</li>
																<li>
																	<a href="/jewellery/mens/shop-all" className="d-block" onClick={handleJewellery}>
																		Men's Jewelry
																	</a>
																</li>
															</ul>
														</li>
													</Col>
													<Col xl={6}>
														<li>
															<p className="d-block mb-2 header-title-link">WOMEN'S BY METAL</p>
															<ul className="sub-header-link">
																<li>
																	<a href="/rings/women-platinum-wedding-bands" className="d-block">
																		Platinum
																	</a>
																</li>
																<li>
																	<a href="/rings/women-silver-wedding-bands" className="d-block">
																		Silver
																	</a>
																</li>
																<li>
																	<a href="/rings/women-yellow-gold-wedding-bands" className="d-block">
																		Yellow Gold
																	</a>
																</li>
																<li>
																	<a href="/rings/women-white-gold-wedding-bands" className="d-block">
																		White Gold
																	</a>
																</li>
																<li>
																	<a href="/rings/women-rose-gold-wedding-bands" className="d-block">
																		Rose Gold
																	</a>
																</li>
															</ul>
														</li>
													</Col>
													<Col xl={6}>
														<li className="my-3">
															<p className="d-block mb-2 header-title-link">MEN'S BY METAL</p>
															<ul className="sub-header-link">
																<li>
																	<a href="/rings/mens-platinum-wedding-bands" className="d-block">
																		Platinum
																	</a>
																</li>
																<li>
																	<a href="/rings/mens-silver-wedding-bands" className="d-block">
																		Silver
																	</a>
																</li>
																<li>
																	<a href="/rings/mens-yellow-gold-wedding-bands" className="d-block">
																		Yellow Gold
																	</a>
																</li>
																<li>
																	<a href="/rings/mens-white-gold-wedding-bands" className="d-block">
																		White Gold
																	</a>
																</li>
																<li>
																	<a href="/rings/mens-rose-gold-wedding-bands" className="d-block">
																		Rose Gold
																	</a>
																</li>
															</ul>
														</li>
													</Col>
												</Row>
											</ul>
										</Accordion.Body>
									</Accordion.Item>
								)}
								{headerLink.includes("Diamond") && (
									<Accordion.Item eventKey="2">
										<Accordion.Header>DIAMOND</Accordion.Header>
										<Accordion.Body>
											<ul className="header-link">
												<Row>
													<Col xl={6}>
														<li>
															<a href="/diamond" className="d-block header-title-link-header ">
																DIAMOND
															</a>
															<p className="d-block header-title-link">NATURAL DIAMOND</p>
															<a
																href="/diamond/natural"
																className="d-block mt-1 mb-2 sec-header-link"
																onClick={handleHideAllMultiProductSelectionSetting}
															>
																search natural diamonds
															</a>

															<ul className="sub-header-link">
																<div className="d-flex align-items-center w-100 mb-2">
																	<div className="w-50">
																		<li>
																			<a href="/diamond/round" className="d-block" onClick={handleHideAllMultiProductSelectionSetting}>
																				Round
																			</a>
																		</li>
																		<li>
																			<a href="/diamond/oval" className="d-block" onClick={handleHideAllMultiProductSelectionSetting}>
																				Oval
																			</a>
																		</li>
																		<li>
																			<a href="/diamond/cushion" className="d-block" onClick={handleHideAllMultiProductSelectionSetting}>
																				Cushion
																			</a>
																		</li>
																		<li>
																			<a href="/diamond/pear" className="d-block" onClick={handleHideAllMultiProductSelectionSetting}>
																				Pear
																			</a>
																		</li>
																		<li>
																			<a href="/diamond/princess" className="d-block" onClick={handleHideAllMultiProductSelectionSetting}>
																				Princess
																			</a>
																		</li>
																	</div>
																	<div className="w-50">
																		<li>
																			<a href="/diamond/emerald" className="d-block" onClick={handleHideAllMultiProductSelectionSetting}>
																				Emerald
																			</a>
																		</li>
																		<li>
																			<a href="/diamond/radiant" className="d-block" onClick={handleHideAllMultiProductSelectionSetting}>
																				Radiant
																			</a>
																		</li>
																		<li>
																			<a href="/diamond/marquise" className="d-block" onClick={handleHideAllMultiProductSelectionSetting}>
																				Marquise
																			</a>
																		</li>
																		<li>
																			<a href="/diamond/asscher" className="d-block" onClick={handleHideAllMultiProductSelectionSetting}>
																				Asscher
																			</a>
																		</li>
																		<li>
																			<a href="/diamond/heart" className="d-block" onClick={handleHideAllMultiProductSelectionSetting}>
																				Heart
																			</a>
																		</li>
																	</div>
																</div>
																<li className="mb-1">
																	<a href="/diamond/colored" className="d-block" onClick={handleHideAllMultiProductSelectionSetting}>
																		Colored Diamonds
																	</a>
																</li>
																<li>
																	<a href="/diamond/super-ideal-cut" className="d-block" onClick={handleHideAllMultiProductSelectionSetting}>
																		Super Ideal Diamonds
																	</a>
																</li>
															</ul>
														</li>
													</Col>

													<Col xl={6}>
														<li className="mt-3">
															<p className="d-block header-title-link">LAB DIAMONDS</p>
															<a
																href="/diamond/lab-diamonds-search"
																className="d-block mt-1 mb-2 sec-header-link"
																onClick={handleHideAllMultiProductSelectionSetting}
															>
																Search Lab Diamonds
															</a>
															<a
																href="/diamond/lab-created-colored-diamonds"
																className="d-block mt-1 mb-2 sec-header-link"
																onClick={handleHideAllMultiProductSelectionSetting}
															>
																Lab Colored Diamonds
															</a>
														</li>
													</Col>
												</Row>
											</ul>
										</Accordion.Body>
									</Accordion.Item>
								)}
								{headerLink.includes("Gemstone") && (
									<Accordion.Item eventKey="3">
										<Accordion.Header>GEMSTONE</Accordion.Header>
										<Accordion.Body>
											<ul className="header-link">
												<Row>
													<Col xl={6}>
														<li className="mb-3">
															<a href="/gemstone" className="d-block header-title-link-header">
																GEMSTONE
															</a>
															<p className="d-block mb-2 header-title-link">DESIGN YOUR OWN GEMSTONE RING</p>
															<ul className="sub-header-link">
																<li>
																	<a href="/engagement-rings/gemstone-engagement-ring-settings" className="d-block" onClick={handleGemstoneSetting}>
																		Start with a Setting
																	</a>
																</li>
																<li>
																	<a href="/gemstone/shop-all" className="d-block" onClick={handleGemstone}>
																		Start with a Gemstone
																	</a>
																</li>
																<li>
																	<a href="/gemstone/moissanite" className="d-block" onClick={handleGemstone}>
																		Moissanite
																	</a>
																</li>
																<li>
																	<a href="/gemstone/sapphire" className="d-block" onClick={handleGemstone}>
																		Sapphire
																	</a>
																</li>
																<li>
																	<a href="/gemstone/emerald" className="d-block" onClick={handleGemstone}>
																		Emerald
																	</a>
																</li>
																<li>
																	<a href="/gemstone/alexandrite" className="d-block" onClick={handleGemstone}>
																		Alexandrite
																	</a>
																</li>
																<li>
																	<a href="/gemstone/aquamarine" className="d-block" onClick={handleGemstone}>
																		Aquamarine
																	</a>
																</li>
																<li>
																	<a href="/gemstone/morganite" className="d-block" onClick={handleGemstone}>
																		Morganite
																	</a>
																</li>
																<li>
																	<a href="/gemstone/ruby" className="d-block" onClick={handleGemstone}>
																		Ruby
																	</a>
																</li>
																<li>
																	<a href="/gemstone/colored-diamond" className="d-block" onClick={handleGemstone}>
																		Colored Diamond
																	</a>
																</li>
															</ul>
														</li>
													</Col>
													<Col xl={6}>
														<li className="mb-3">
															<p className="d-block mb-2 header-title-link">Shope by color</p>
															<ul className="sub-header-link">
																<li>
																	<a href="/gemstone/blue" className="d-block" onClick={handleGemstone}>
																		Blue
																	</a>
																</li>
																<li>
																	<a href="/gemstone/green" className="d-block " onClick={handleGemstone}>
																		Green
																	</a>
																</li>
																<li>
																	<a href="/gemstone/pink" className="d-block" onClick={handleGemstone}>
																		Pink
																	</a>
																</li>
																<li>
																	<a href="/gemstone/teal" className="d-block" onClick={handleGemstone}>
																		Teal
																	</a>
																</li>
																<li>
																	<a href="/gemstone/purple" className="d-block" onClick={handleGemstone}>
																		Purple
																	</a>
																</li>
																<li>
																	<a href="/gemstone/peach" className="d-block" onClick={handleGemstone}>
																		Peach
																	</a>
																</li>
															</ul>
														</li>
													</Col>
													<Col xl={6}>
														<li className="mb-3">
															<p className="d-block mb-2 header-title-link">Shop by shape</p>
															<ul className="sub-header-link">
																<li>
																	<a href="/gemstone/oval" className="d-block" onClick={handleGemstone}>
																		Oval
																	</a>
																</li>
																<li>
																	<a href="/gemstone/round" className="d-block" onClick={handleGemstone}>
																		Round
																	</a>
																</li>
																<li>
																	<a href="/gemstone/emerald-shape" className="d-block" onClick={handleGemstone}>
																		Emerald
																	</a>
																</li>
																<li>
																	<a href="/gemstone/cushion" className="d-block" onClick={handleGemstone}>
																		Cushion
																	</a>
																</li>
																<li>
																	<a href="/gemstone/radiant" className="d-block" onClick={handleGemstone}>
																		Radiant
																	</a>
																</li>
															</ul>
														</li>
													</Col>
													<Col xl={6}>
														<li>
															<p className="d-block mb-2 header-title-link">preset gemstone rings</p>
															<ul className="sub-header-link">
																<li>
																	<a href="/engagement-rings/moissanite-rings" className="d-block">
																		Moissanite Rings
																	</a>
																</li>
																<li>
																	<a href="/engagement-rings/sapphire-rings" className="d-block">
																		Sapphire Rings
																	</a>
																</li>
																<li>
																	<a href="/engagement-rings/aquamarine-rings" className="d-block">
																		Aquamarine Rings
																	</a>
																</li>
																<li>
																	<a href="/engagement-rings/morganite-rings" className="d-block">
																		Morganite Rings
																	</a>
																</li>
															</ul>
														</li>
													</Col>
												</Row>
											</ul>
										</Accordion.Body>
									</Accordion.Item>
								)}
								{headerLink.includes("Jewellery") && (
									<Accordion.Item eventKey="4">
										<Accordion.Header>JEWELRY</Accordion.Header>
										<Accordion.Body>
											<ul className="header-link">
												<Row>
													<Col xl={6}>
														<li className="mb-3">
															<a href="/jewellery" className="d-block header-title-link-header">
																JEWELRY
															</a>
															<p className="d-block mb-2 header-title-link">JEWELRY</p>
															<ul className="sub-header-link">
																<li>
																	<a href="/jewellery/Rings/shop-all" className="d-block" onClick={handleJewellery}>
																		Rings
																	</a>
																</li>
																<li>
																	<a href="/jewellery/Earrings/shop-all" className="d-block" onClick={handleJewellery}>
																		Earrings
																	</a>
																</li>
																<li>
																	<a href="/jewellery/Necklaces/shop-all" className="d-block" onClick={handleJewellery}>
																		Necklaces
																	</a>
																</li>
																<li>
																	<a href="/jewellery/Bracelets/shop-all" className="d-block" onClick={handleJewellery}>
																		Bracelets
																	</a>
																</li>
																<li>
																	<a href="/jewellery/mens/shop-all" className="d-block" onClick={handleJewellery}>
																		Men's Jewelry
																	</a>
																</li>

																<li>
																	<a href="/jewellery/new-arrivals/shop-all" onClick={handleJewellery} className="d-block">
																		New Arrivals
																	</a>
																</li>
															</ul>

															<a
																href="/jewellery/all-category/shop-all"
																onClick={handleJewellery}
																className="d-flex align-items-center mt-2 shope-all-link"
															>
																Shope all Jewelry
																<i className="bi bi-chevron-right d-block"></i>
															</a>
														</li>
													</Col>
													<Col xl={6}>
														<li className="mb-3">
															<p className="d-block mb-2 header-title-link">SHOP BY STYLE</p>
															<ul className="sub-header-link">
																<li>
																	<a href="/jewellery/lab-diamond-jewelry/shop-by-style" onClick={handleJewellery} className="d-block">
																		lab diamond Jewelry
																	</a>
																</li>
																<li>
																	<a href="/jewellery/Diamond Studs/shop-by-style" className="d-block" onClick={handleJewellery}>
																		diamond stud Earrings
																	</a>
																</li>
																<li>
																	<a href="/jewellery/birthstone-jewelry/shop-by-style" onClick={handleJewellery} className="d-block">
																		Birthstone Jewelry
																	</a>
																</li>
																<li>
																	<a href="/jewellery/Tennis Bracelets/shop-by-style" onClick={handleJewellery} className="d-block">
																		Tennis Bracelets
																	</a>
																</li>
																<li>
																	<a href="/jewellery/gemstone-jewelry/shop-by-style" onClick={handleJewellery} className="d-block">
																		Gemstone Jewelry
																	</a>
																</li>
																<li>
																	<a href="/jewellery/diamond-jewelry/shop-by-style" onClick={handleJewellery} className="d-block">
																		Diamond Jewelry
																	</a>
																</li>
																<li>
																	<a href="/jewellerytennis-jewelry/shop-by-style" onClick={handleJewellery} className="d-block">
																		Tennis necklaces Jewelry
																	</a>
																</li>
															</ul>
														</li>
													</Col>
													<Col xl={6}>
														<li>
															<p className="d-block mb-2 header-title-link">Design your own</p>
															<ul className="sub-header-link">
																<li>
																	<a href="/jewellery/Earrings/shop-all" className="d-block" onClick={() => handlemakeJewellery("make Earrings")}>
																		Diamond Earrings
																	</a>
																</li>
																<li>
																	<a href="/jewellery/Necklaces/shop-all" className="d-block" onClick={() => handlemakeJewellery("make Necklaces")}>
																		Diamond necklaces
																	</a>
																</li>
																<li>
																	<a href="/gemstone/design-your-own-necklace" className="d-block" onClick={handleStartWithGemstoneNecklace}>
																		gemstone necklaces
																	</a>
																</li>
																<li>
																	<a href="/gemstone/shop-all" className="d-block" onClick={handleGemstone}>
																		gemstone ring
																	</a>
																</li>
																<li>
																	<a href="/diamond/design-your-own-engagement-ring" className="d-block" onClick={removeProductArray}>
																		diamond ring
																	</a>
																</li>
															</ul>
														</li>
													</Col>
												</Row>
											</ul>
										</Accordion.Body>
									</Accordion.Item>
								)}
								{headerLink.includes("Gift") && (
									<Accordion.Item eventKey="5">
										<Accordion.Header> GIFTS </Accordion.Header>
										<Accordion.Body>
											<ul className="header-link">
												<Row>
													<Col xl={6}>
														<li className="mb-3">
															<a href="/gifts" className="d-block header-title-link-header">
																Gifts
															</a>
															<p className="d-block header-title-link">Jewelry Gift</p>
															<p className="d-block mt-1 mb-2 sec-header-link">Top Gift</p>
															<ul className="sub-header-link">
																<li>
																	<a href="/engagement-rings/gemstone-engagement-ring-settings" onClick={handleGemstoneSetting} className="d-block">
																		Gemstone Rings
																	</a>
																</li>

																<li>
																	<a href="/jewellery/Diamond Studs/shop-by-style" className="d-block" onClick={handleJewellery}>
																		Diamond Stud Earrings
																	</a>
																</li>
																<li>
																	<a href="/jewellery/tennis-bracelet/shop-by-style" onClick={handleJewellery} className="d-block">
																		Tennis Bracelets
																	</a>
																</li>
																<li>
																	<a href="/jewellery/Earrings/Hoop Earrings" onClick={handleJewellery} className="d-block">
																		Hoop Earrings
																	</a>
																</li>
																<li>
																	<a href="/jewellery/all-category/gold-jewellery" onClick={handleJewellery} className="d-block">
																		Gold Jewelry
																	</a>
																</li>
															</ul>
														</li>
													</Col>
													<Col xl={6}>
														<li className="mb-3">
															<p className="d-block mb-2 header-title-link">gifts by RECIPIENT</p>
															<ul className="sub-header-link">
																<li>
																	<a href="/jewellery/all-category/gifts-for-her" onClick={handleJewellery} className="d-block">
																		gifts for her
																	</a>
																</li>
																<li>
																	<a href="/jewellery/all-category/gifts-for-him" onClick={handleJewellery} className="d-block">
																		gifts for him
																	</a>
																</li>
																<li>
																	<a href="/jewellery/all-category/gifts-for-everyone" onClick={handleJewellery} className="d-block">
																		gifts for everyone
																	</a>
																</li>
															</ul>
														</li>
													</Col>

													<Col xl={6}>
														<li className="mb-3">
															<p className="d-block mb-2 header-title-link">design your own</p>
															<ul className="sub-header-link">
																<li>
																	<a href="/jewellery/Earrings/shop-all" className="d-block" onClick={() => handlemakeJewellery("make Earrings")}>
																		Diamond Earrings
																	</a>
																</li>

																<li>
																	<a href="/jewellery/Necklaces/shop-all" className="d-block" onClick={() => handlemakeJewellery("make Necklaces")}>
																		diamond necklaces
																	</a>
																</li>
																<li>
																	<a href="/gemstone/design-your-own-necklace" className="d-block" onClick={handleStartWithGemstoneNecklace}>
																		gemstone necklaces
																	</a>
																</li>
															</ul>
														</li>
													</Col>
													<Col xl={6}>
														<li>
															<p className="d-block mb-2 header-title-link">more gift ideas</p>
															<ul className="sub-header-link">
																<li>
																	<a
																		href="/jewellery/all-category/gifts-under-250"
																		className="d-block"
																		onClick={handleHideAllMultiProductSelectionSetting}
																	>
																		Gifts Under $250
																	</a>
																</li>
																<li>
																	<a
																		href="/jewellery/all-category/gifts-under-500"
																		className="d-block"
																		onClick={handleHideAllMultiProductSelectionSetting}
																	>
																		Gift under $500
																	</a>
																</li>
															</ul>
														</li>
													</Col>
												</Row>
											</ul>
										</Accordion.Body>
									</Accordion.Item>
								)}
								{headerLink.includes("About") && (
									<Accordion.Item eventKey="6">
										<Accordion.Header> ABOUT </Accordion.Header>
										<Accordion.Body>
											<ul className="header-link">
												<Row>
													<Col xl={6}>
														<li>
															<a href="/about-us" className="d-block mb-2 header-title-link">
																About Us
															</a>
															<ul className="sub-header-link">
																<li>
																	<a href={ROUTES.RETURN_POLICY} className="d-block">
																		Return Policy
																	</a>
																</li>
																<li>
																	<a href={ROUTES.TERMS_AND_CONDITIONS} className="d-block">
																		Terms & Conditions
																	</a>
																</li>
																<li>
																	<a href={ROUTES.FAQ_PAGE} className="d-block">
																		FAQ'S
																	</a>
																</li>
																<li>
																	<a href={ROUTES.PRIVACY_POLICY} className="d-block">
																		Privacy Policy
																	</a>
																</li>
																<li>
																	<a href={ROUTES.SHIPPING_POLICY} className="d-block">
																		Shipping Policy
																	</a>
																</li>
																<li>
																	<a href={ROUTES.BLOG_PAGE} className="d-block">
																		blog
																	</a>
																</li>
																<li>
																	<a href={ROUTES.CONTACT_US} className="d-block">
																		Contact Us
																	</a>
																</li>
															</ul>
														</li>
													</Col>
												</Row>
											</ul>
										</Accordion.Body>
									</Accordion.Item>
								)}
							</Accordion>
							<div className="offcanvas-widget-area">
								<div className="off-canvas-contact-widget">
									<ul>
										<li className="d-flex align-items-center">
											<i className="bi bi-phone"></i>
											<a href="/contact-us" className="d-block">
												Contact us
											</a>
										</li>
										{!userLogin ? (
											<li className="d-flex align-items-center">
												<i className="bi bi-person"></i>
												<a href="#!" className="d-block" onClick={() => setIsModelOpen(true)}>
													Sign in
												</a>
											</li>
										) : (
											<li className="d-flex align-items-center">
												<i className="bi bi-person"></i>
												<a href="/my-account" className="d-block">
													My Account
												</a>
											</li>
										)}
										<li className="d-flex align-items-center">
											<i className="bi bi-heart d-block"></i>
											<a href="/wishlist" className="d-block">
												Wish list
											</a>
										</li>
										<li className="d-flex align-items-center">
											<i className="bi bi-bag d-block"></i>
											<a href="/cart" className="d-block">
												My bag
											</a>
										</li>
									</ul>
								</div>
							</div>
						</Offcanvas.Body>
					</Offcanvas>
				)}
			</header>

			{/* LOGIN POPUP OPEN */}
			<ModelComponent show={isModelOpen} onHide={() => setIsModelOpen(false)}>
				<Login setIsModelOpen={setIsModelOpen} />
			</ModelComponent>
			{/* LOGIN POPUP END */}
			{/* NOTIFICTION POPUP OPEN */}
			<Offcanvas show={show} onHide={() => setShow(false)} placement="end" className="notification-canvas">
				<Offcanvas.Header>
					<Offcanvas.Title>Notification</Offcanvas.Title>
					<div className="d-flex align-items-center">
						<button
							type="button"
							className="d-block me-2 notification-clear--button"
							onClick={clearAllNotitfication}
							disabled={isEmpty(allNotifications.data)}
						>
							Clear all
						</button>
						<button type="button" className="d-block" onClick={() => setShow(false)}>
							<i className="bi bi-x-lg"></i>
						</button>
					</div>
				</Offcanvas.Header>
				<Offcanvas.Body>
					{show && (
						<>
							{!isEmpty(allNotifications?.data) ? (
								<>
									<InfiniteScroll
										dataLength={allNotifications?.data?.length}
										next={fetchMoreData}
										hasMore={allNotifications?.data?.length < allNotifications?.totalNotification}
										height="calc(100vh - 180px)"
										loader={<Loader />}
									>
										{allNotifications?.data?.map((item, index) => {
											return (
												<div key={index} className="notification-card">
													<div className="notification_data">
														<h4 className="title">{item?.subject}</h4>
														<button type="button" className="d-block" onClick={() => removeNotitfication(item?.id)}>
															<i className="bi bi-x"></i>
														</button>
													</div>
													<p className="sub_title">{item?.content}</p>
													<span className="notification_date">
														<TimeFormatted timestamp={item?.createdAt} />
													</span>
												</div>
											);
										})}
									</InfiniteScroll>
								</>
							) : (
								<>
									<span className="d-block text-center no-details">No Notification</span>
								</>
							)}
						</>
					)}
				</Offcanvas.Body>
			</Offcanvas>
			{/* NOTIFICTION POPUP END */}
		</>
	);
};

export default Header;
