import React, { useState } from "react";
import { Container, Row } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Login.css";
import { ROUTES } from "../../constants";
import { axios } from "../../services";
import { toaster } from "../../utils/helper.util";
import { useDispatch } from "react-redux";
import Validators from "../validations/Validator";
import Textinput from "../ui/input.component";
import { login } from "../../redux/actions/auth.action";
import { Icon } from "@iconify/react";
import { useEffect } from "react";
import { FORGOT_PASSWORD } from "../../constants/routes";

const loginRules = {
	email: "required|email",
	password: "required|password",
};

const Login = ({ setIsModelOpen }) => {
	const { pathname } = useLocation();
	const [modelType, setModelType] = useState("login");
	const [passwordShow, setPasswordShow] = useState(true);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const loginInitialState = {
		email: "",
		password: "",
	};

	const [customerLogin, setCustomerLogin] = useState({ ...loginInitialState });

	const handleOnChange = (e) => {
		setCustomerLogin((prev) => ({
			...prev,
			[e.target.name]: e.target.value,
		}));
	};

	const handleLogin = (data) => {
		axios
			.authPost(ROUTES.LOGIN, data)
			.then((response) => {
				if (response?.status === 200) {
					toaster.success("Login sucessfully.");
					localStorage?.setItem("access_token", response?.data?.token);
					dispatch(
						login({
							token: response?.data?.token,
							user: response?.data?.customer,
						})
					);
					setIsModelOpen(false);
					if (pathname === "/register") {
						navigate("/");
					}
				}
			})
			.catch(({ errors: error }) => {
				toaster.error(error?.message);
			});
	};

	const onKeyEnter = (event) => {
		if (event.keyCode === 13) {
			handleLogin(customerLogin);
		}
	};

	useEffect(() => {
		setPasswordShow(true);
	}, [modelType]);

	return (
		<>
			<Container className="overlay_login-content">
				<Row className="justify-content-center box_content_accountdestop text-center">
					<div className="formlogin-destop">
						<div className="login_primary CustomerLoginForm">
							<div className="login-icon-popup-login">
								<Link to="#!">
									<img src="../../../../images/logo.png" width={110} alt="Fazhop - Jewelry And Accessories Responsive Shopify Theme" />
								</Link>
							</div>
							<Validators formData={customerLogin} rules={loginRules}>
								{({ onSubmit, errors }) => {
									return (
										<>
											<h2 className="title-tab-login">Great to have you back!</h2>
											<div className="mailrrr mt-3">
												<div className="form-group">
													<Textinput
														type="email"
														className="form-control"
														label="Email"
														name="email"
														placeholder="Email address"
														required=""
														value={customerLogin?.email}
														onChange={(e) => handleOnChange(e)}
														error={errors?.email}
														onKeyDown={(e) => onKeyEnter(e)}
													/>
												</div>
												<div className="form-group password_icon">
													<Textinput
														type={passwordShow === true ? "password" : "text"}
														className="form-control "
														placeholder="Password"
														name="password"
														required=""
														label="password"
														value={customerLogin?.password}
														onChange={(e) => handleOnChange(e)}
														error={errors?.password}
														onKeyDown={(e) => onKeyEnter(e)}
													/>
													<button
														type="button"
														className="login_password_icon"
														onClick={() => {
															setPasswordShow(!passwordShow);
														}}
													>
														{passwordShow === true ? (
															<Icon icon="mdi:eye-off-outline" className="eyes_icon" />
														) : (
															<Icon
																icon="mdi:eye-outline"
																className="eyes_icon"
																onClick={() => {
																	setPasswordShow(!passwordShow);
																}}
															/>
														)}
													</button>
												</div>
												<div className="form-check">
													<Link to={FORGOT_PASSWORD} onClick={() => setIsModelOpen(false)} className="RecoverPassword">
														Forgot your password?
													</Link>
												</div>
												<button
													type="button"
													className="btn btn-dark w-100"
													onClick={() => {
														return setModelType("login"), onSubmit(handleLogin);
													}}
												>
													Log In
												</button>
											</div>
											<div className="or_creat my-3">
												<div className="box-register">
													<span className="or-register mr-2">Don't have an account? &nbsp;</span>
													<button
														onClick={() => {
															return setIsModelOpen(false), navigate("/register");
														}}
														className="jsCreate_account"
													>
														Register now
														<i className="ml-2 ti-arrow-right" />
													</button>
												</div>
											</div>
										</>
									);
								}}
							</Validators>
						</div>
					</div>
				</Row>
			</Container>
		</>
	);
};

export default Login;
