import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CONFIG, ROUTES } from "../../constants";
import { useSelector } from "react-redux";
import { footerInformationArray } from "../../constants/product-constant";
import moment from "moment/moment";

const Footer = () => {
	const {
		general: { email, logo, contact_number },
		social_link: { facebook, instagram, linkedin, skype, twitter },
	} = useSelector((state) => state?.multiProduct?.appSettings);
	const headerLink = localStorage?.getItem("headerLink")?.split(",");

	return (
		<>
			<div className="footer-widget-area">
				<div className="footer-top section-padding">
					<Container>
						<Row>
							<Col xl={3} md={6}>
								<div className="widget-item">
									<div className="widget-title">
										<div className="widget-logo">
											<Link to="/">
												<img
													src={CONFIG.API_BASE_URL + logo}
													alt="brand logo"
													onError={(e) => {
														e.currentTarget.onerror = null;
														e.currentTarget.src = "../../../../images/default-images/our-product.svg";
													}}
													className="object-fit-cover"
												/>
											</Link>
										</div>
									</div>
								</div>
							</Col>
							<Col xl={3} md={6}>
								<div className="widget-item">
									<h6 className="widget-title">Contact Us</h6>
									<div className="widget-body">
										<address className="contact-block">
											<ul>
												<li>
													<i className="bi bi-envelope"></i>
													<Link to="mailto:info@evaanta.com" className="text-decoration-none">
														{email}
													</Link>
												</li>
												<li>
													<i className="bi bi-telephone"></i>
													<Link to="#!" className="text-decoration-none">
														{contact_number}
													</Link>
												</li>
											</ul>
										</address>
									</div>
								</div>
							</Col>
							<Col xl={3} md={6}>
								<div className="widget-item">
									<h6 className="widget-title">Information</h6>
									<div className="widget-body">
										<ul className="info-list">
											{headerLink?.includes("About") ? (
												<li>
													<Link to={ROUTES.ABOUT_US} className="text-decoration-none">
														about us
													</Link>
												</li>
											) : (
												""
											)}
											{footerInformationArray?.map((item, index) => {
												return (
													<li key={index}>
														<Link to={item?.path} className="text-decoration-none">
															{item?.name}
														</Link>
													</li>
												);
											})}
										</ul>
									</div>
								</div>
							</Col>
							<Col xl={3} md={6}>
								<div className="widget-item">
									<h6 className="widget-title">Follow Us</h6>
									<div className="widget-body social-link">
										<Link to={facebook} target="_blank">
											<i className="bi bi-facebook"></i>
										</Link>
										<Link to={twitter} target="_blank">
											<i className="bi bi-twitter"></i>
										</Link>
										<Link to={instagram} target="_blank">
											<i className="bi bi-instagram"></i>
										</Link>
										<Link to={linkedin} target="_blank">
											<i className="bi bi-linkedin"></i>
										</Link>
										<Link to={skype}>
											<i className="bi bi-skype"></i>
										</Link>
									</div>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
				<div className="footer-bottom">
					<Container>
						<Row>
							<Col xl={12} className="text-center text-white copy-wright">
								© Copyright © {moment().format("YYYY")} Evaanta. All Rights Reserved.
							</Col>
						</Row>
					</Container>
				</div>
			</div>
		</>
	);
};

export default Footer;
