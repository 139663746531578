import { Route, Routes } from "react-router-dom";
import AuthLayout from "./components/layout/auth-layout.component";
import "./App.css";
import "./Responsive.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-datepicker/dist/react-datepicker.css";
import Page404 from "./pages/Dashboard/Page404.page";
import LoadingLayout from "./components/layout/loading-layout.component";
import { filterRoute } from "./constants/routes";
import MyRoutes, { myRoutes } from "./routes/MyRoutes";
import { Suspense } from "react";

function App() {
	return (
		<Suspense fallback={""}>
			<Routes>
				<Route path="/*" element={<LoadingLayout />}>
					{myRoutes?.map((route, i) => (
						<Route key={i} path={filterRoute(route.path)} element={<MyRoutes component={route.element} {...route} />} />
					))}
					<Route path="*" element={<AuthLayout NotshowBreadCrumb={false}>{<Page404 />}</AuthLayout>} />
				</Route>
			</Routes>
		</Suspense>
	);
}

export default App;
