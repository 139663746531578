import React, { useEffect, useState } from "react";
import moment from "moment";

const TimeFormatted = ({ timestamp }) => {
	const [currentTime, setCurrentTime] = useState(moment());

	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentTime(moment());
		}, 1000);

		return () => clearInterval(interval);
	}, []);

	const getTimeDifferenceString = (date) => {
		const now = currentTime.clone().add(2, "seconds");
		const duration = moment.duration(now.diff(date));

		const days = duration.asDays();
		const hours = duration.asHours();
		const minutes = duration.asMinutes();

		if (days >= 1) {
			return moment(date).format("ddd, DD MMM YYYY, hh:mm A");
		} else if (hours >= 1) {
			return `${Math.floor(hours)} hour${Math.floor(hours) === 1 ? "" : "s"} ago`;
		} else if (minutes >= 1) {
			return `${Math.floor(minutes)} minute${Math.floor(minutes) === 1 ? "" : "s"} ago`;
		} else {
			return "Just now";
		}
	};

	return (
		<div>
			<p className="xl:text-xs md:text-[5px]">{getTimeDifferenceString(timestamp)}</p>
		</div>
	);
};

export default TimeFormatted;
