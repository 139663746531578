import React from "react";
import { isEmpty } from "../../utils/helper.util";

export default function ValidationMessages({ errors, name, data }) {
	return (
		<>
			{isEmpty(errors) && <h2>{errors}</h2>}

			{errors?.map((error, i) => (
				<div key={i} className={`${data ? "validation-errors" : "validation-error my-2"}`}>
					{error?.replace(":attribute", name)?.replace(/ when .*$/, "")}
				</div>
			))}
		</>
	);
}
