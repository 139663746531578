import React, { lazy } from "react";
import { ROUTES } from "../constants";
import _ from "lodash";
import AuthStorage from "../utils/authStorage";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import AuthLayout from "../components/layout/auth-layout.component";

const Registerpage = lazy(() => import("../pages/Dashboard/Register.page"));
const HomePage = lazy(() => import("../pages/Dashboard/Home.page"));
const ProductFilterPage = lazy(() => import("../pages/Product/productFilter.component"));
const MyAccountpage = lazy(() => import("../pages/Dashboard/MyAccount.page"));
const Whislistpage = lazy(() => import("../pages/Dashboard/Whislist.page"));
const ContactUspage = lazy(() => import("../pages/Dashboard/ContactUs.page"));
const GemstonePage = lazy(() => import("../pages/Gemstone/gemstone.component"));
const GemstoneFilterPage = lazy(() => import("../pages/Gemstone/gemstoneFilter.component"));
const GemstoneDetailPage = lazy(() => import("../pages/Gemstone/gemstoneDetail.component"));
const DiamondFilterPage = lazy(() => import("../pages/Diamond/diamondFilter.component"));
const DiamondDetailPage = lazy(() => import("../pages/Diamond/diamondDetail.component"));
const ColorDiamondPage = lazy(() => import("../pages/Diamond/colorDiamondFilter.component"));
const ColorDiamondPages = lazy(() => import("../pages/Diamond/colorDiamondFilter.component"));
const Cartpage = lazy(() => import("../pages/Dashboard/Cart.page"));
const WeddingRings = lazy(() => import("../pages/Dashboard/WeddingRings.page"));
const AboutUsPage = lazy(() => import("../pages/Dashboard/AboutUs.page"));
const PrivacyPolicyPage = lazy(() => import("../pages/Dashboard/PrivacyPolicy.page"));
const TermsAndConditionsPage = lazy(() => import("../pages/Dashboard/TermsAndConditions.page"));
const EngagementRingPage = lazy(() => import("../pages/Dashboard/EngagementRing.page"));
const DiamondPage = lazy(() => import("../pages/Diamond/daimond.component"));
const WeddingRingFilterPage = lazy(() => import("../pages/Product/weddingRingFilter.component"));
const JewelryPage = lazy(() => import("../pages/Dashboard/Jewelry.page"));
const JewelleryDetailPage = lazy(() => import("../pages/Jewellery/jewelleryDetail.component"));
const JewelleryFilterPage = lazy(() => import("../pages/Jewellery/jewelleryFilter.component"));
const BlogPage = lazy(() => import("../pages/Dashboard/Blog.page"));
const BlogDetails = lazy(() => import("../pages/blog/BlogDetails.component"));
const MultiProductDetail = lazy(() => import("../pages/multiProductDetail/completeRing.component"));
const MultiProductEarringDetail = lazy(() => import("../pages/multiProductDetail/multiProductEarringDetails.component"));
const MultiProductNecklaceDetails = lazy(() => import("../pages/multiProductDetail/multiProductNecklaceDetails.component"));
const FaqPage = lazy(() => import("../pages/Dashboard/Faq.page"));
const ReturnPolicyPage = lazy(() => import("../pages/Dashboard/ReturnPolicy.page"));
const ShippingPolicyPage = lazy(() => import("../pages/Dashboard/ShippingPolicy"));
const GiftsPage = lazy(() => import("../pages/Dashboard/Gifts.page"));
const ForgotPassword = lazy(() => import("../pages/Dashboard/ForgotPassword.page"));
const ResetPassword = lazy(() => import("../pages/Dashboard/ResetPassword.page"));
const Cut = lazy(() => import("../pages/Education/Cut.component"));
const Color = lazy(() => import("../pages/Education/Color.component"));
const Clarity = lazy(() => import("../pages/Education/Clarity.component"));
const Carat = lazy(() => import("../pages/Education/Carat.component"));
const Certificate = lazy(() => import("../pages/Education/Certificate.component"));
const Cost = lazy(() => import("../pages/Education/Cost.component"));
const EducationDetails = lazy(() => import("../pages/Education/EducationDetails/EducationDetails.component"));
const PrincessDetails = lazy(() => import("../pages/Education/EducationDetails/PrincessDetails.component"));
const CustionDetails = lazy(() => import("../pages/Education/EducationDetails/CushionDetails.component"));
const OvalDetails = lazy(() => import("../pages/Education/EducationDetails/OvalDetails.component"));
const EmeraldDetails = lazy(() => import("../pages/Education/EducationDetails/EmeraldDetails.component"));
const PearDetails = lazy(() => import("../pages/Education/EducationDetails/PearDetails.component"));
const AsscherDetails = lazy(() => import("../pages/Education/EducationDetails/AsscherDetails.component"));
const RadiantDetails = lazy(() => import("../pages/Education/EducationDetails/RadiantDetails.component"));
const MarquiseDetails = lazy(() => import("../pages/Education/EducationDetails/Marquise.component"));
const HeartDetails = lazy(() => import("../pages/Education/EducationDetails/HeartDetails.component"));
const isUserLogin = AuthStorage?.isUserAuthenticated();
export const myRoutes = [
	{
		path: ROUTES.HOME,
		element: HomePage,
		permissions: [],
		isAuth: false,
		isShowBreadCrumb: false,
	},
	{
		path: ROUTES.REGISTER,
		element: Registerpage,
		permissions: [!isUserLogin],
		isAuth: false,
		isShowBreadCrumb: false,
	},
	{
		path: ROUTES.GEMSTONE_PAGE,
		element: GemstonePage,
		permissions: ["Gemstone"],
		isAuth: false,
		isShowBreadCrumb: false,
	},
	{
		path: ROUTES.GEMSTONE_FILTER_PAGE,
		element: GemstoneFilterPage,
		permissions: ["Gemstone"],
		isAuth: false,
		isShowBreadCrumb: false,
	},
	{
		path: ROUTES.GEMSTONE_DETAIL_PAGE,
		element: GemstoneDetailPage,
		permissions: ["Gemstone"],
		isAuth: false,
		isShowBreadCrumb: false,
	},
	{
		path: ROUTES.DIAMOND_FILTER_PAGE,
		element: DiamondFilterPage,
		permissions: ["Diamond"],
		isAuth: false,
		isShowBreadCrumb: false,
	},
	{
		path: ROUTES.DIAMOND_DETAILS,
		element: DiamondDetailPage,
		permissions: ["Diamond"],
		isAuth: false,
		isShowBreadCrumb: false,
	},
	{
		path: ROUTES.LAB_COLOR_DIAMOND,
		element: ColorDiamondPage,
		permissions: ["Diamond"],
		isAuth: false,
		isShowBreadCrumb: false,
	},
	{
		path: ROUTES.NATURAL_COLOR_DIAMOND,
		element: ColorDiamondPage,
		permissions: ["Diamond"],
		isAuth: false,
		isShowBreadCrumb: false,
	},
	{
		path: ROUTES.COLOR_DIAMOND,
		element: ColorDiamondPages,
		permissions: ["Diamond"],
		isAuth: false,
		isShowBreadCrumb: false,
	},
	{
		path: ROUTES.PRODUCT_FILTER_PAGE,
		element: ProductFilterPage,
		permissions: ["Engagement Rings"],
		isAuth: false,
		isShowBreadCrumb: false,
	},
	{
		path: ROUTES.MY_ACCOUNT,
		element: MyAccountpage,
		permissions: [],
		isAuth: true,
		isShowBreadCrumb: false,
	},
	{
		path: ROUTES.CART,
		element: Cartpage,
		permissions: [],
		isAuth: false,
		isShowBreadCrumb: false,
	},
	{
		path: ROUTES.WEDDING_RINGS,
		element: WeddingRings,
		permissions: ["Wedding Rings"],
		isAuth: false,
		isShowBreadCrumb: false,
	},
	{
		path: ROUTES.WHISLIST,
		element: Whislistpage,
		permissions: [],
		isAuth: false,
		isShowBreadCrumb: false,
	},
	{
		path: ROUTES.CONTACT_US,
		element: ContactUspage,
		permissions: [],
		isAuth: false,
		isShowBreadCrumb: false,
	},
	{
		path: ROUTES.MY_ACCOUNT_WITH_ORDER_PAYMENT_ID,
		element: MyAccountpage,
		permissions: [],
		isAuth: true,
		isShowBreadCrumb: false,
	},
	{
		path: ROUTES.ABOUT_US,
		element: AboutUsPage,
		permissions: ["About"],
		isAuth: false,
		isShowBreadCrumb: false,
	},
	{
		path: ROUTES.PRIVACY_POLICY,
		element: PrivacyPolicyPage,
		permissions: [],
		isAuth: false,
		isShowBreadCrumb: false,
	},
	{
		path: ROUTES.TERMS_AND_CONDITIONS,
		element: TermsAndConditionsPage,
		permissions: [],
		isAuth: false,
		isShowBreadCrumb: false,
	},
	{
		path: ROUTES.ENGAGEMENT_RINGS,
		element: EngagementRingPage,
		permissions: ["Engagement Rings"],
		isAuth: false,
		isShowBreadCrumb: false,
	},
	{
		path: ROUTES.DIAMOND_PAGE,
		element: DiamondPage,
		permissions: ["Diamond"],
		isAuth: false,
		isShowBreadCrumb: false,
	},
	{
		path: ROUTES.WEDDING_RING_FILTER,
		element: WeddingRingFilterPage,
		permissions: ["Wedding Rings"],
		isAuth: false,
		isShowBreadCrumb: false,
	},
	{
		path: ROUTES.GEMSTONE_DETAIL_PAGE,
		element: GemstoneDetailPage,
		permissions: [],
		isAuth: false,
		isShowBreadCrumb: false,
	},
	{
		path: ROUTES.JEWELRY_PAGE,
		element: JewelryPage,
		permissions: ["Jewellery"],
		isAuth: false,
		isShowBreadCrumb: false,
	},
	{
		path: ROUTES.JEWELRY_DETAIL_PAGE,
		element: JewelleryDetailPage,
		permissions: [],
		isAuth: false,
		isShowBreadCrumb: false,
	},
	{
		path: ROUTES.JWELLERY_FILTER,
		element: JewelleryFilterPage,
		permissions: ["Jewellery"],
		isAuth: false,
		isShowBreadCrumb: false,
	},
	{
		path: ROUTES.BLOG_PAGE,
		element: BlogPage,
		permissions: [],
		isAuth: false,
		isShowBreadCrumb: false,
	},
	{
		path: ROUTES.BLOG_DETAILS,
		element: BlogDetails,
		permissions: [],
		isAuth: false,
		isShowBreadCrumb: false,
	},
	{
		path: ROUTES.COMPLETE_RING,
		element: MultiProductDetail,
		permissions: [],
		isAuth: false,
		isShowBreadCrumb: false,
	},
	{
		path: ROUTES.COMPLETE_EARRING,
		element: MultiProductEarringDetail,
		permissions: [],
		isAuth: false,
		isShowBreadCrumb: false,
	},
	{
		path: ROUTES.COMPLETE_NECKLACE,
		element: MultiProductNecklaceDetails,
		permissions: [],
		isAuth: false,
		isShowBreadCrumb: false,
	},
	{
		path: ROUTES.FAQ_PAGE,
		element: FaqPage,
		permissions: [],
		isAuth: false,
		isShowBreadCrumb: false,
	},
	{
		path: ROUTES.RETURN_POLICY,
		element: ReturnPolicyPage,
		permissions: [],
		isAuth: false,
		isShowBreadCrumb: false,
	},
	{
		path: ROUTES.SHIPPING_POLICY,
		element: ShippingPolicyPage,
		permissions: [],
		isAuth: false,
		isShowBreadCrumb: false,
	},
	{
		path: ROUTES.GIFTS,
		element: GiftsPage,
		permissions: ["Gift"],
		isAuth: false,
		isShowBreadCrumb: false,
	},
	{
		path: ROUTES.FORGOT_PASSWORD,
		element: ForgotPassword,
		permissions: [],
		isAuth: false,
		isShowBreadCrumb: false,
	},
	{
		path: ROUTES.RESET_PASSWORD,
		element: ResetPassword,
		permissions: [],
		isAuth: false,
		isShowBreadCrumb: false,
	},
	{
		path: ROUTES.CUT_PAGE,
		element: Cut,
		permissions: [],
		isAuth: false,
		isShowBreadCrumb: false,
	},
	{
		path: ROUTES.COLOR_PAGE,
		element: Color,
		permissions: [],
		isAuth: false,
		isShowBreadCrumb: false,
	},
	{
		path: ROUTES.CLARITY_PAGE,
		element: Clarity,
		permissions: [],
		isAuth: false,
		isShowBreadCrumb: false,
	},
	{
		path: ROUTES.CARAT_PAGE,
		element: Carat,
		permissions: [],
		isAuth: false,
		isShowBreadCrumb: false,
	},
	{
		path: ROUTES.CERTIFICATE_PAGE,
		element: Certificate,
		permissions: [],
		isAuth: false,
		isShowBreadCrumb: false,
	},
	{
		path: ROUTES.COST_PAGE,
		element: Cost,
		permissions: [],
		isAuth: false,
		isShowBreadCrumb: false,
	},
	{
		path: ROUTES.EDUCATION_DETAILS,
		element: EducationDetails,
		permissions: [],
		isAuth: false,
		isShowBreadCrumb: false,
	},
	{
		path: ROUTES.PRINCESS_DETAILS,
		element: PrincessDetails,
		permissions: [],
		isAuth: false,
		isShowBreadCrumb: false,
	},
	{
		path: ROUTES.CUSHION_DETAILS,
		element: CustionDetails,
		permissions: [],
		isAuth: false,
		isShowBreadCrumb: false,
	},
	{
		path: ROUTES.OVAl_DETAILS,
		element: OvalDetails,
		permissions: [],
		isAuth: false,
		isShowBreadCrumb: false,
	},
	{
		path: ROUTES.EMERALD_DETAILS,
		element: EmeraldDetails,
		permissions: [],
		isAuth: false,
		isShowBreadCrumb: false,
	},
	{
		path: ROUTES.PEAR_DETAILS,
		element: PearDetails,
		permissions: [],
		isAuth: false,
		isShowBreadCrumb: false,
	},
	{
		path: ROUTES.ASSCHER_DETAILS,
		element: AsscherDetails,
		permissions: [],
		isAuth: false,
		isShowBreadCrumb: false,
	},
	{
		path: ROUTES.RADIANT_DETAILS,
		element: RadiantDetails,
		permissions: [],
		isAuth: false,
		isShowBreadCrumb: false,
	},
	{
		path: ROUTES.MARQUIES_DETAILS,
		element: MarquiseDetails,
		permissions: [],
		isAuth: false,
		isShowBreadCrumb: false,
	},
	{
		path: ROUTES.HEART_DETAILS,
		element: HeartDetails,
		permissions: [],
		isAuth: false,
		isShowBreadCrumb: false,
	},
];

export default function MyRoutes({ component: Component, permissions = [], isAuth = false, isShowBreadCrumb = false }) {
	const isUserLogin = AuthStorage?.isUserAuthenticated();
	const headersLink = useSelector((state) => state?.multiProduct?.headersLink);
	let hasAccess = true;

	if (permissions.length > 0) {
		let intersection = _.intersection(permissions, headersLink);
		hasAccess = intersection.length > 0;
	}

	if (permissions?.includes(true)) {
		hasAccess = true;
	}

	if (!hasAccess || (isAuth && !isUserLogin)) {
		return <Navigate to="/" />;
	}

	return (
		<AuthLayout NotshowBreadCrumb={!isShowBreadCrumb}>
			<Component />
		</AuthLayout>
	);
}
