import React, { useEffect, useState } from "react";
import { GET_PRODUCT_USING_SEARCH } from "../../constants/routes";
import { axios } from "../../services";
import { CONFIG } from "../../constants";
import { Link } from "react-router-dom";
import { isEmpty } from "../../utils/helper.util";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./uiComponent.css";

const DynamicSearch = () => {
	const [searchText, setSearchText] = useState("");
	const [searchProductData, setSearchProductData] = useState([]);

	const getProductOfSearchText = () => {
		axios
			.authGet(GET_PRODUCT_USING_SEARCH, {
				searchText: searchText,
				rowsPerPage: 5,
			})
			.then(({ data: data }) => {
				setSearchProductData(data);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		if (searchText?.length >= 4) {
			getProductOfSearchText();
		} else {
			setSearchProductData([]);
		}
	}, [searchText]);

	return (
		<div className="search--box-wrapper">
			<input
				type="text"
				value={searchText}
				className="search--input w-100"
				placeholder="search"
				onBlur={() => {
					if (searchText.length === 0) return setSearchText(""), setSearchProductData([]);
				}}
				onChange={(e) => setSearchText(e?.target?.value)}
			/>
			{!isEmpty(searchProductData) && (
				<div className="search--data-box">
					{searchProductData?.map((item, index) => {
						return (
							<Link
								key={index}
								to={`/jewellery-detail/${item?.id}`}
								target="_blank"
								onClick={() => {
									return setSearchText(""), setSearchProductData([]);
								}}
							>
								<LazyLoadImage
									className="pri-img object-fit-cover me-2"
									src={`${CONFIG.API_BASE_URL}productsFiles/${item?.stockId}/${item?.sku}/main.jpg`}
									alt="product"
									onError={({ currentTarget }) => {
										currentTarget.onerror = null;
										currentTarget.src = "../../../../images/our-product-default-bannner.svg";
									}}
									effect="blur"
								/>
								<div>
									<span className="search--title">{item?.title}</span>
									<span className="d-block mt-2 seach--price">$ {item?.totalPrice}</span>
								</div>
							</Link>
						);
					})}
				</div>
			)}
		</div>
	);
};

export default DynamicSearch;
