import React from "react";

const Page404 = () => {
	return (
		<section className="custom--padding-box">
			<div className="section-container p-l-r">
				<div className="page-404">
					<div className="content-page-404">
						<div className="title-error">404</div>
						<div className="subtitle">Oops! That page can't be found. </div>
						<div className="sub-error">We're really sorry but we can't seem to find the page you were looking for.</div>
						<a className="button" href="/">
							Back The Homepage
							<i className="gg-arrow-long-right"></i>
						</a>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Page404;
