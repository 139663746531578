import axios from "axios";
import AuthStorage from "../utils/authStorage";
import * as CONFIG from "../constants/config";

const instance = axios.create({
	baseURL: CONFIG.API_BASE_URL,
});

instance.interceptors.response.use(
	(response) => {
		return response.data;
	},
	(error) => {
		if (error.response.status === 401) {
			AuthStorage.deauthenticateUser();
		}

		return Promise.reject({
			errors: error.response.data.error,
		});
	}
);

const prepredHeader = (header) => {
	let token = AuthStorage.getToken();
	return {
		Authorization: `Bearer ${token}`,
		...header,
	};
};

export const get = (url, params) => {
	return instance.get(url, {
		params,
	});
};

export const post = (url, data) => {
	return instance.post(url, data);
};

export const put = (url, params) => {
	return instance.put(url, {
		params,
	});
};

export const destroy = (url) => {
	return instance.delete(url);
};

export const authGet = (url, params, header = {}) => {
	return instance.get(url, {
		params,
		headers: prepredHeader(header),
	});
};

export const authPost = (url, data, header = {}) => {
	return instance.post(url, data, {
		headers: prepredHeader(header),
	});
};

export const authPut = (url, payload, header = {}) => {
	return instance.put(url, payload, {
		headers: prepredHeader(header),
	});
};

export const authDelete = (url, header = {}) => {
	return instance.delete(url, {
		headers: prepredHeader(header),
	});
};
