import React, { createContext, useEffect, useState } from "react";
import Validator from "validatorjs";
import en from "validatorjs/src/lang/en";

export const ValidationContext = createContext(null);

export default function Validators({ setErrors: errorsData = null, customValidationMessages = {}, formData = {}, rules = {}, children }) {
	const [submitted, setIsSubmitted] = useState(false);

	const _registerEmailValidations = () => {
		Validator.register(
			"email",
			function (value) {
				let regExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
				return regExp.test(value);
			},
			"*Please enter a valid email address."
		);
	};
	const _registerPincodeValidations = () => {
		Validator.register(
			"pincode",
			function (value) {
				return value.replace(/\D/g, "").length >= 6;
			},
			"*Please enter a valid pincode."
		);
	};

	const _registerPasswordValidations = () => {
		Validator.register(
			"password",
			function (value) {
				_registerConfirmPasswordValidations(value);
				return value.length >= 5;
			},
			"*Minimum 5 characters."
		);
	};

	const _registerConfirmPasswordValidations = (password) => {
		Validator.register(
			"confirm_password",
			function (value) {
				return value === password;
			},
			"Passwords must match"
		);
	};

	const _registerNumbereValidations = () => {
		Validator.register(
			"telephone",
			function (value) {
				let regExp = /^\d{10}$/;
				return regExp.test(value);
			},
			"*Please enter a valid telephone."
		);
	};

	const _registerMobileNumbereValidations = () => {
		Validator.register(
			"mobile",
			function (value) {
				let regExp = /^\d{10}$/;
				return regExp.test(value);
			},
			"*Please enter a valid mobile no."
		);
	};

	const _registerFileuploadValidations = () => {
		Validator.register(
			"mimes",
			function (value, requirement, attribute) {
				if (typeof value === "string") {
					return true;
				}
				const allowedExtensions = requirement ? requirement?.split(",") : [];
				const fileExtension = value[0]?.name?.split(".").pop();
				if (!allowedExtensions.includes(fileExtension)) {
					return false; // Invalid file type
				}
				return true;
			},
			"The :attribute must be a file of type :mime_types"
		);

		Validator.register(
			"max_file_size",
			function (value, requirement, attribute) {
				if (typeof value === "string") {
					return true;
				}

				if (value.size > Number(requirement)) {
					return false;
				}

				return true;
			},
			"Max file size is :max_size"
		);

		Validator.register(
			"min_file_size",
			function (value, requirement, attribute) {
				if (typeof value === "string") {
					return true;
				}

				if (value.size < Number(requirement)) {
					return false;
				}

				return true;
			},
			"Min file size is :min_size"
		);
	};

	function formatBytes(bytes, decimals = 2) {
		if (!+bytes) return "0 Bytes";
		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
		const i = Math.floor(Math.log(bytes) / Math.log(k));
		return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
	}

	const imageValidationMessagesModification = (errors, rules) => {
		for (let [field, errorMsgs] of Object.entries(errors)) {
			errors[field] = errorMsgs.map((messages) => {
				if (messages.includes(":mime_types")) {
					let attributeMimeTypes = rules[field]
						?.split("|")
						.find((e) => e.includes("mimes"))
						.replace("mimes:", "");
					messages = messages.replace(":mime_types", attributeMimeTypes).replace(":attribute", field);
				}

				if (messages.includes(":max_size")) {
					let attributeValue = rules[field]
						?.split("|")
						.find((e) => e.includes("max_file_size"))
						.replace("max_file_size:", "");
					messages = messages.replace(":max_size", formatBytes(attributeValue));
				}

				if (messages.includes(":min_size")) {
					let attributeValue = rules[field]
						?.split("|")
						.find((e) => e.includes("min_file_size"))
						.replace("min_file_size:", "");
					messages = messages.replace(":min_size", formatBytes(attributeValue));
				}

				return messages;
			});
		}

		return errors;
	};

	useEffect(() => {
		if (submitted) {
			let _isValidationFail = isValidationFail();
			if (false === _isValidationFail) {
				setErrors({});
			}
		}
	}, [formData]);

	const [errors, setErrors] = useState(null);
	useEffect(() => {
		if (errorsData) {
			setErrors(errorsData);
		}
	}, [errorsData]);

	const onSubmit = (callback) => {
		let isValidationFailed = isValidationFail();
		if (false === isValidationFailed) {
			setErrors({});
			callback(formData);
			setIsSubmitted(false);
		} else {
			setIsSubmitted(true);
		}
	};

	const isValidationFail = () => {
		Validator.setMessages("en", en);
		_registerEmailValidations();
		_registerPincodeValidations();
		_registerNumbereValidations();
		_registerMobileNumbereValidations();
		_registerFileuploadValidations();
		_registerPasswordValidations();
		_registerConfirmPasswordValidations();

		let validation = new Validator(formData, rules, customValidationMessages);
		validation.setAttributeFormatter(function (attribute) {
			return ":attribute";
		});

		if (validation.fails()) {
			let validationErrors = imageValidationMessagesModification(validation.errors.errors, rules);
			setErrors(validationErrors);
			return true;
		}
		return false;
	};

	return <>{children({ onSubmit, errors })}</>;
}
