import React, { useState } from "react";
import ValidationMessages from "../validations/ValidationMessages";

const Textinput = ({
	type,
	label,
	placeholder = "Add placeholder",
	classLabel = "text-sm block mb-[6px] leading-[16px]",
	className = "",
	classGroup = "",
	name,
	readOnly,
	value,
	error,
	icon,
	disabled,
	id,
	horizontal,
	validate,
	isMask,
	msgTooltip,
	description,
	hasicon,
	onChange,
	options,
	onFocus,
	defaultValue,
	maxLength,
	onKeyDown,
	onKeyPress,
	children,
	...rest
}) => {
	const [open, setOpen] = useState(false);
	const handleOpen = () => {
		setOpen(!open);
	};

	return (
		<div className={` ${error ? "has-error" : ""} ${horizontal ? "row" : ""} ${validate ? "has-success" : ""}`}>
			<div className={`col ${horizontal ? "col-md-10" : ""}`}>
				{name && (
					<input
						autoComplete={value}
						type={type === "password" && open === true ? "text" : type}
						{...rest}
						className={`${error ? "has-error" : ""} ${className}`}
						placeholder={placeholder}
						readOnly={readOnly}
						name={name}
						defaultValue={defaultValue}
						disabled={disabled}
						id={id}
						onChange={onChange}
						value={value}
						maxLength={maxLength}
						onKeyDown={onKeyDown}
					/>
				)}
				{children && children}
			</div>

			<ValidationMessages errors={error} name={label} />
		</div>
	);
};

export default Textinput;
